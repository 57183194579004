/**
 * @file 全局配置（逐渐废除config_v2.js）
 */
// 产品梳理，一期只保留英文，简体中文，繁体中文，汇率保留USD
const config: any = {
  orgId: 9001,
  supportLanguages: [
    {
      lang: 'en-us',
      text: 'English',
      prefix: '$',
      suffix: 'USD'
    },
    // {
    //   lang: 'tr-tr',
    //   text: 'Türkçe',
    //   prefix: '₺',
    //   suffix: 'TRY'
    // },
    // {
    //   lang: 'ko-kr',
    //   text: '한국어',
    //   prefix: '₩',
    //   suffix: 'KRW'
    // },
    {
      lang: 'zh-cn',
      text: '简体中文',
      prefix: '￥',
      suffix: 'CNY'
    },
    // {
    //   lang: 'ru-ru',
    //   // text: 'русский язык',
    //   text: 'русский',
    //   prefix: '₽',
    //   suffix: 'RUB'
    // },
    {
      lang: 'zh-tw',
      text: '繁體中文',
      prefix: 'NT$',
      suffix: 'TWD'
    }
    // {
    //   lang: 'ja-jp',
    //   text: '日本語',
    //   prefix: '￥',
    //   suffix: 'JPY',
    //   invisible: true
    // },
    // {
    //   lang: 'pt-pt',
    //   text: 'Portuguese',
    //   prefix: '￥',
    //   suffix: 'JPY',
    //   invisible: true
    // }
  ],
  supportLanguagesMap: <any>{
    'en-US': {
      lang: 'en-us',
      text: 'English',
      prefix: '$',
      suffix: 'USD',
      zendesk: 'en-us'
    },
    // 'tr-TR': {
    //   lang: 'tr-tr',
    //   text: 'Türkçe',
    //   prefix: '₺',
    //   suffix: 'TRY',
    //   zendesk: 'tr'
    // },
    // 'ko-KR': {
    //   lang: 'ko-kr',
    //   text: '한국어',
    //   prefix: '₩',
    //   suffix: 'KRW',
    //   zendesk: 'ko-kr'
    // },
    'zh-CN': {
      lang: 'zh-cn',
      text: '简体中文',
      prefix: '￥',
      suffix: 'CNY',
      zendesk: 'zh-cn'
    },
    // 'ru-RU': {
    //   lang: 'ru-ru',
    //   text: 'русский',
    //   prefix: '₽',
    //   suffix: 'RUB',
    //   zendesk: 'ru'
    // },
    'zh-TW': {
      lang: 'zh-tw',
      text: '繁體中文',
      prefix: 'NT$',
      suffix: 'TWD',
      zendesk: 'zh-tw'
    }
    // 'ja-JP': {
    //   lang: 'ja-jp',
    //   text: '日本語',
    //   prefix: '￥',
    //   suffix: 'JPY'
    // },
    // 'pt-PT': {
    //   lang: 'pt-pt',
    //   text: 'Portuguese',
    //   prefix: '￥',
    //   suffix: 'JPY'
    // }
  },
  supportLegal: [
    { prefix: '$', suffix: 'USD' }
    // { prefix: '₽', suffix: 'RUB' },
    // { prefix: '₺', suffix: 'TRY' },
    // { prefix: '￥', suffix: 'CNY' },
    // { prefix: '₩', suffix: 'KRW' },
    // { prefix: 'NT$', suffix: 'TWD' }
  ],
  supportLegalMap: {
    'en-US': { prefix: '$', suffix: 'USD' }
    // 'tr-TR': { prefix: '₺', suffix: 'TRY' },
    // 'ru-RU': { prefix: '₽', suffix: 'RUB' },
    // 'zh-CN': { prefix: '￥', suffix: 'CNY' },
    // 'ko-KR': { prefix: '₩', suffix: 'KRW' },
    // 'zh-TW': { prefix: 'NT$', suffix: 'TWD' }
  }
};

export default config;

