import { apiHost } from './setup';

export const wsUrl: any = {
  // 行情ws地址
  quoteWs: `${apiHost.ws}/ws/quote/v1`,
  // quoteWs: 'wss://bws.HashKey.com/ws/quote/v1',
  // 用户相关ws(订单、资产等)
  userWs: `${apiHost.ws}/api/ws/user`,
  // 订单ws地址
  // orderWs: `${apiHost.ws}/api/ws/user`
};
