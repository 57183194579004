<template>
  <el-tabs>
    <slot />
  </el-tabs>
</template>

<script lang="ts">
// namespaced components
export default {
  name: 'HkTabs'
};
</script>

<script lang="ts" setup>
// const props = defineProps();    
</script>

<style lang="scss" scoped>
</style>