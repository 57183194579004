<template>
  <el-dialog
    v-model="dialogVisible"
    title="Tips"
    width="480px"
    :modal="true"
    :show-close="false"
    class="ModalDialog2Fa"
    destroy-on-close
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div>
      <div class="header">
        {{ $t("安全验证") }}
        <img
          src="@/assets/img/Login/close.png"
          width="24"
          height="24"
          @click="handleClose"
        >
      </div>
      <div class="desc">
        <span>
          {{ $t("为了您的资金安全，请完成下列安全验证") }}
        </span>
      </div>
    </div>
    
    <el-form
      ref="loginFormRef"
      :model="verifyParams"
      :rules="verifyParamsRules"
      label-position="top"
      class="form pb12"
      :hide-required-asterisk="true"
      @submit.prevent
    >
      <el-form-item
        v-if="user.userinfo.email"
        :label="$t('邮箱验证码: ') + user.userinfo.email"
        prop="emailCodeValidator"
        :error="errorMsgEmail"
      >
        <VerifyCodeInput
          v-model="verifyParams.emailCode"
          :disabled="sendEmailCodeDisabled"
          @send-code="showEmailGeekTest"
        />
      </el-form-item>

      <div
        v-if="(user.userinfo.mobile && user.userinfo.bindGA)"
        class="flex-row-items-center verify-tab mb12"
      >
        <div
          v-if="user.userinfo.bindGA"
          :class="verityType === 'ga' ? 'active-tab': ''"
          @click="verityType = 'ga'"
        >
          {{ $t('谷歌验证') }}
        </div>
        <div
          v-if="user.userinfo.mobile"
          :class="verityType === 'mobile' ? 'active-tab': ''"
          @click="verityType = 'mobile'"
        >
          {{ $t('短信') }}
        </div>
      </div>
    
      <el-form-item
        v-if="user.userinfo.mobile"
        v-show="verityType === 'mobile'"
        :label="$t('短信验证码: ') + user.userinfo.mobile"
        prop="codeValidator"
        :error="errorMsg"
      >
        <VerifyCodeInput
          v-model="verifyParams.mobileCode"
          :disabled="sendMobileCodeDisabled"
          @send-code="showSmsGeekTest"
        />
      </el-form-item>
      <el-form-item
        v-if="user.userinfo.bindGA"
        v-show="verityType == 'ga'"
        :label="$t('输入谷歌验证码:')"
        prop="codeValidator"
        :error="errorMsg"
      >
        <hk-input v-model="verifyParams.gaCode" class="input-lg" />
      </el-form-item>
    </el-form>
    <div class="footer">
      <hk-button
        class="false-sg-lg text-md-m gray-700"
        @click="handleClose"
      >
        {{ $t('取消') }}
      </hk-button>
      <hk-button
        class="false-primary-lg text-md-m base-white"
        :disabled="loading"
        :loading="loading"
        @click="handleSubmit"
      >
        {{ $t('确定') }}
      </hk-button>
    </div>
  </el-dialog>
  <Captcha
    ref="captcha"
    @captcha-result="captchaResult"
  />
</template>
<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import type { FormRules } from 'element-plus';
import Captcha from '@c/common/Captcha.vue';
import { toast } from '@/utils/toast';
import { userApi } from '@/config/api';
// import CONST from '@/config/const';
import { useUserStore } from '@/store/User';
import { useI18n } from 'vue-i18n';
import VerifyCodeInput from '@/components/common/VerifyCodeInput.vue';
const { t } = useI18n();
const emits = defineEmits(['up-visible', 'confirm']);

const user = useUserStore();

const handleClose = () => {
  emits('up-visible', false);
  // 改为开启弹窗时清空数据
  // clearData();
};
  
// 验证类型
const verityType = ref<string>('ga');
  
// const verityTypeList = computed(() => {
//   return user.userinfo.registerType === 1 ? ['ga', 'mobile'] : ['ga', 'email'];
// });
  
onMounted(() => {
  // 1 手机   2 邮箱
  // 这里是需要错开验证 优先验证 GA

  // 优先ga 其次短信
  if (user.userinfo.bindGA) {
    verityType.value = 'ga';
  } else { 
    verityType.value = 'mobile';
  }
});
  
// 扩展参数根据需求补充
const props = defineProps({
  // 弹窗
  visible: Boolean,
  loading: {
    type: Boolean,
    default: false
  },
  // verityType: {
  //   type: String,
  //   default: 'email'
  // },
  // 发送验证码类型
  sendType: {
    type: String,
    default: '1'
  }
});
const dialogVisible = computed(() => {
  return props.visible;
});
const loading = computed(() => {
  return props.loading;
});

watch(dialogVisible, (val: boolean) => {
  if (val === true) {
    clearData();
  }
});
  
// ga验证码校验
function codeValidator(rule: any, value: any, callback: any): void {
  if (!value) {
    errorMsg.value = '';
    callback();
  }
}

// ga验证码校验
function emailCodeValidator(rule: any, value: any, callback: any): void {
  if (!value) {
    errorMsgEmail.value = '';
    callback();
  }
}
// form校验规则
const verifyParamsRules: any = reactive<FormRules>({
  codeValidator: [{ validator: codeValidator, trigger: 'blur' }],
  emailCodeValidator: [{ validator: emailCodeValidator, trigger: 'blur' }]
});
  
const sendEmailCodeDisabled = ref<boolean>(false);
const sendMobileCodeDisabled = ref<boolean>(false);
// 错误信息
const errorMsg = ref<string>('');
const errorMsgEmail = ref<string>('');
  interface verifyParamsType {
    gaCode: string;
    emailCode: string;
    emailOrderId: string;
    mobileCode: string;
    mobileOrderId: string;
  }
const verifyParams = reactive<verifyParamsType>({
  gaCode: '', // 谷歌验证码
  emailOrderId: '',
  emailCode: '',
  mobileCode: '',
  mobileOrderId: ''
});

const clearData = () => {
  verifyParams.gaCode = '';
  verifyParams.emailOrderId = '';
  verifyParams.emailCode = '';
  verifyParams.mobileCode = '';
  verifyParams.mobileOrderId = '';
  errorMsg.value = '';
  errorMsgEmail.value = '';
  sendEmailCodeDisabled.value = false;
  sendMobileCodeDisabled.value = false;
  if (user.userinfo.bindGA) {
    verityType.value = 'ga';
  } else {
    verityType.value = 'mobile';
  }
};

// 极验
const captcha = ref();
const smsCb: any = ref();
const emailCb: any = ref();
const sendCaptchaType: any = ref(0); // email: 1, sms: 2

const showEmailGeekTest = (successCallback: any, errorCallback: any) => {
  emailCb.value = { successCallback, errorCallback };
  sendCaptchaType.value = 1;
  captcha.value.showBox();
};

const showSmsGeekTest = (successCallback: any, errorCallback: any) => {
  smsCb.value = { successCallback, errorCallback };
  sendCaptchaType.value = 2;
  captcha.value.showBox();
};

const captchaResult = (result: object) => {
  if (result) {
    if (sendCaptchaType.value === 1) {
      sendEmailVerifyCode(emailCb.value.successCallback, result);
    }
    if (sendCaptchaType.value === 2) {
      sendMobileVerifyCode(smsCb.value.successCallback, result);
    }
  }
};
  
// 获取验证码
const sendMobileVerifyCode = async(successCallback: any, result: any) => {
  if (sendMobileCodeDisabled.value) {
    return;
  }
  sendMobileCodeDisabled.value = true;
  // TODO 人机验证暂时未接入
  const params: any = {
    captcha_id: result.captcha_id,
    captcha_response: JSON.stringify(result),
    challenge: '',
    type: props.sendType,
    receiver_type: 1
  };
    // 登录后获取手机验证码
  const res: any = await userApi.sendVerifyCode(params);
    
  sendMobileCodeDisabled.value = false;
  if (res.success) {
    successCallback();
    verifyParams.mobileOrderId = res.data.orderId;
    // changeBindGaFrom.index = res.data.index;
    toast.success(t('短信验证码发送成功'));
  } else {
    // errorCallback();
    // toast.error(t('发送验证码失败'));
    toast.error(res.message);
  }
};
  
const sendEmailVerifyCode = async(successCallback: any, result: any) => {
  if (sendEmailCodeDisabled.value) {
    return;
  }
  sendEmailCodeDisabled.value = true;
  // TODO 人机验证暂时未接入
  const params: any = {
    captcha_id: result.captcha_id,
    captcha_response: JSON.stringify(result),
    challenge: '',
    type: props.sendType,
    receiver_type: 2
  };
    // 登录后获取邮箱验证码
  const res: any = await userApi.sendVerifyCode(params);
  sendEmailCodeDisabled.value = false;
  if (res.success) {
    successCallback();
    verifyParams.emailOrderId = res.data.orderId;
    toast.success(t('邮箱验证码发送成功'));
    // changeBindGaFrom.index = res.data.index;
  } else {
    toast.error(t('发送验证码失败'));
  }
};
  
watch(() => [verifyParams.mobileOrderId, verifyParams.emailOrderId], () => { 
  if (errorMsg.value === t('请发送验证码') && verifyParams.mobileOrderId) { 
    errorMsg.value = '';
  }

  if (errorMsgEmail.value === t('请发送验证码') && verifyParams.emailOrderId) { 
    errorMsgEmail.value = '';
  }
});
  
// 校验验证码
const checkUnbind = () => {

  if (user.userinfo.email) {
    if (!verifyParams.emailOrderId) { 
      errorMsgEmail.value = t('请发送验证码');
      return true;
    }
  
    if (!verifyParams.emailCode) { 
      errorMsgEmail.value = t('请输入验证码');
      return true;
    }
  }

  if (verityType.value !== 'ga') {
    if (user.userinfo.mobile) {
      if (!verifyParams.mobileOrderId) {
        errorMsg.value = t('请发送验证码');
        return true;
      }
      if (!verifyParams.mobileCode) {
        errorMsg.value = t('请输入验证码');
        return true;
      }
    }
  } else {
    if (user.userinfo.bindGA) {
      if (!verifyParams.gaCode) {
        errorMsg.value = t('请输入验证码');
        return true;
      }
    }
  }
  return false;
};
  
// 提交 验证码数据
const handleSubmit = async() => {
  if (checkUnbind()) {
    return;
  }

    //   const params = {
    //             mobileCode: mfaContent['mobileCode'] || '',
    //             mobileOrderId: mfaContent['mobileOrderId'] || '',
    //             emailCode: mfaContent['emailCode'] || '',
    //             emailOrderId: mfaContent['emailOrderId'] || '',
    //             verifyCaseType: props.verifyCaseType,
    //             gaCode: mfaContent['gaCode'] || '',
    //             authType: mfaContent['gaCode'] && mfaContent['emailCode'] ? "GA" : "MOBILE"
    //         }
    interface paramsType {
        authType: string | number;
        gaCode: string;
        emailCode: string;
        emailOrderId: string;
        mobileCode: string;
        mobileOrderId: string;
        verifyCaseType: string;
    }
    const params = reactive<paramsType>({
      emailOrderId: verifyParams.emailOrderId,
      emailCode: verifyParams.emailCode,
      verifyCaseType: props.sendType,
      gaCode: '',
      mobileOrderId: '',
      mobileCode: '',
      authType: ''
    });
    if (verityType.value === 'ga') {
      params.authType = 'GA';
      params.gaCode = verifyParams.gaCode;
      // 授权类型，1：手机，2邮件，3ga
      // auth_type:  ? 3 : verityType.value === 'email' ? 2 : 1,
      // order_id: verityType.value === 'ga' ? '' : verifyParams.order_id,
      // verify_code: verifyParams.verify_code
    } else {
      params.authType = 'MOBILE';
      params.mobileOrderId = verifyParams.mobileOrderId;
      params.mobileCode = verifyParams.mobileCode;
    }
    emits('confirm', params);
    // emits('up-visible', false);
    // clearData();
};
  
</script>
  <style lang="scss">
  .ModalDialog2Fa {
    border-radius: 12px;
    .el-input__wrapper {
      height: 48px;
    }
    .el-dialog__header {
      display: none;
    }
    .el-dialog__footer {
      display: none;
    }
    .el-dialog__body {
      border-radius: 12px;
      padding: 24px;
      background: var(--base-white) !important;
      box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
    }
    .el-input__inner {
      height: 56px;
    }
    .el-form-item__label {
      color: var(--gray-700);
    }
  }
  </style>
  <style lang="scss" scoped>
  .ModalDialog2Fa {
    
    .verify-tab {
      border-bottom: 1px solid var(--gray-300);
    }
    .verify-tab div {
      margin-right: 16px;
      cursor: pointer;
      padding-bottom: 12px;
      color: var(--gray-500);
      &:hover {
        color: var(--text-link);
      }
      &.active-tab {
        color: var(--gray-900);
        border-bottom: 3px solid var(--primary-500);
      }
    }
    .header {
      width: 100%;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: var(--gray-900);
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        cursor: pointer;
      }
    }
    .desc {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      margin: 4px 0 24px;
      color: var(--gray-600);
    }
    .footer {
      color: var(--text-link);
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      padding-bottom: 8px;
      display: flex;
      justify-content: space-between;
      button {
        width: 210px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
  </style>
  