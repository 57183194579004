import {
  createRouter,
  createWebHistory,
  createMemoryHistory,
  NavigationGuard,
  NavigationGuardWithThis,
  NavigationHookAfter,
  RouteRecordRaw
} from 'vue-router';
import { isClient } from '@vueuse/shared';
// eslint-disable-next-line
// @ts-nocheck
import { langs } from '@/config/setup';
import langEach from './langEach';
import autoEach from './authEach';
import stateEach from './stateEach';
import routeList from './routes';

export const routes: RouteRecordRaw[] = [...routeList];

if (langs.length > 1) {
  // 生成多语言路由
  langs.forEach(lang => {
    routeList.forEach(item => {
      routes.push({
        ...item,
        path: `/${lang}/${item.path.replace(/^\/+/, '')}`,
        ...(item.alias
          ? {
            alias: Array.isArray(item.alias)
              ? item.alias.map(alias => `/${lang}/${alias.replace(/^\/+/, '')}`)
              : `/${lang}/${item.alias.replace(/^\/+/, '')}`
          }
          : {}
        ),
        meta: {
          ...item.meta
        }
      });
    });
  });
} else {
  routeList.forEach(item => {
    routes.push({
      ...item,
      path: `/${item.path.replace(/^\/+/, '')}`,
      meta: {
        ...item.meta
      }
    });
  });
}

export const router = createRouter({
  history: isClient ? createWebHistory() : createMemoryHistory(),
  routes: routeList,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || {
      left: 0,
      top: 0
    };
  }
});

/**
 * 添加路由守卫
 *
 * @param {object|array} context 路由守卫
 */

type TypeContext = NavigationGuard[] | {
  beforeEach?: NavigationGuard[],
  afterEach?: NavigationHookAfter[]
};

const routerEach = (context: TypeContext, router: any) => {
  if (Array.isArray(context)) {
    context.forEach(item => router.beforeEach(item));
  } else {
    if (Array.isArray(context.beforeEach)) {
      context.beforeEach.forEach((item: NavigationGuardWithThis<undefined>) => router.beforeEach(item));
    }

    if (Array.isArray(context.afterEach)) {
      context.afterEach.forEach((item: NavigationHookAfter) => {
        router.afterEach(item);
      });
    }
  }
};

export const useRouterEach = (router: any) => {
  routerEach(langEach, router);
  routerEach(autoEach, router);
  routerEach(stateEach, router);
};
