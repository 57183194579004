<template>
  <el-popconfirm>
    <slot />
  </el-popconfirm>
</template>
  
<script lang="ts">
// namespaced components
export default {
  name: 'HkPopconfirm'
};
</script>
  
<script lang="ts" setup>
// const props = defineProps();    
</script>
  
  <style lang="scss" scoped>
  </style>