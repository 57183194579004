/**
 * 币种详情页面（为了构建ssg使用）
 */
import { $api } from '@/config/api';

export const useTokenStore = defineStore('tokenStore', {
  state: () => ({
    // 当前币种详情页的数据
    info: <any>{},
    loading: <boolean>true
  }),
  actions: {
    async getTokenInfo(tokenId: string) {
      if (!tokenId) {
        return;
      }

      const res: any = await $api.token_info({
        token_id: tokenId
      });

      if (res.success) {
        this.loading = false;
        this.info = res.data;
      }
    }
  }
});
