// eslint-disable-next-line
// @ts-nocheck
import { i18n } from '@/lang/i18n';
import { pinia } from '@/store';
import { useCommonStore } from '@/store/common';
import { isPC } from '@/utils/tools';

const { t } = i18n.global;
const commonStore = useCommonStore(pinia);

// 活动路由 11个
const activity = [
  {
    path: '/activity',
    component: () => import('@/pages/activity/Index.vue'),
    meta: {
      layout: 'default',
      // auth: 'login',
      title: t('活动'),
      ssg: {}
    },
    redirect: '/activity/api-customer-rewards',
    children: [
      // {
      //   path: 'user-tasks',
      //   component: () => import('@/pages/activity/NewUserTasks.vue'),
      //   meta: {
      //     class: {
      //       layout: 'NewUserTasks-box',
      //       header: 'NewUserTasks-header'
      //     },
      //     // auth: 'login',
      //     props: { header: { border: false } },
      //     title: t('新手礼包')
      //   },
      //   redirect: !isPC() ? '/h5/activity/user-tasks' : ''
      // },
      {
        path: 'api-customer-rewards',
        component: () => import('@/pages/api-activity/ApiCustomerRewards.vue'),
        meta: {
          layout: 'default',
          props: { header: { border: false } },
          title: t('API客户奖励'),
          ssg: {}
        },
        redirect: !isPC() ? '/h5/activity/api-customer-rewards' : ''
      },
      {
        path: 'celebration',
        component: () => import('@/pages/api-activity/Celebration.vue'),
        meta: {
          layout: 'default',
          props: { header: { border: false } },
          title: t('专业投资者认证 - 奖励500 HSK - HashKey Exchange - 买币更方便，存币更安心'),
          description: t('欢迎专业投资者踏上 HSK 开户之路！活动期间提交并完成专业投资者认证即获得 500 HSK！'),
          ssg: {}
        },
        redirect: !isPC() ? '/h5/activity/celebration' : ''
      }, {
        path: 'mbox',
        component: () => import('@/pages/activity/mbox/web/index.vue'),
        meta: {
          class: {
            layout: 'NewUserTasks-box',
            header: 'NewUserTasks-header'
          },
          // auth: 'login',
          props: { header: { border: false } },
          title: t('HSK 盲盒'),
          ssg: {}
        },
        redirect: !isPC() ? '/h5/activity/mbox' : ''
      },
      {
        path: 'mb412',
        component: () => import('@/pages/activity/MB412.vue'),
        meta: {
          class: {
          },
          // auth: 'login',
          props: { header: { border: false } },
          title: t('fs-2023 香港 Web3 Festival 盲盒'),
          ssg: {}
        },
        redirect: !isPC() ? '/h5/activity/mb412' : ''
      },
      {
        path: 'ZACard',
        component: () => import('@/pages/activity/web/zaCard/Index.vue'),
        meta: {
          class: {
          },
          // auth: 'login',
          props: { header: { border: false } },
          title: t('ZA 虚拟卡福利'),
          ssg: {}
        },
        redirect: !isPC() ? '/h5/activity/ZACard' : ''
      },
      {
        path: 'genesisVip',
        component: () => import('@/pages/activity/web/vip/index.vue'),
        meta: {
          class: {
          },
          // auth: 'login',
          props: { header: { border: false } },
          title: t('创世 VIP')
          // ssg: {}
        },
        redirect: !isPC() ? '/h5/activity/genesisVip' : ''
      },
      {
        path: 'LunarNewYearTrading',
        component: () => import('@/pages/activity/web/newYearTradeHsk/index.vue'),
        meta: {
          class: {},
          props: { header: { border: false } },
          title: t('trade-home-2024-page-title龙礼盛典交易赛'),
          ssg: {}
        },
        redirect: !isPC() ? '/h5/activity/LunarNewYearTrading' : ''
      },
      {
        path: 'anniversary',
        component: () => import('@/pages/activity/web/anniversary/Index.vue'),
        meta: {
          class: {},
          props: { header: { border: false } },
          title: t('seo.activity-anniversary.title'),
          keyword: t('seo.activity-anniversary.keywords'),
          description: t('seo.activity-anniversary.description'),
          ssg: {}
        },
        redirect: !isPC() ? '/h5/hkhome' : ''
      },
      {
        path: 'tradeGiveHsk',
        component: () => import('@/pages/activity/tradeGiveHsk/web/Index.vue'),
        meta: {
          title: t('tradeGiveHsk.activityDetail.title'),
          ssg: {}
        },
        redirect: !isPC() ? '/h5/activity/tradeGiveHsk' : ''
      }
    ]
  },
  {
    path: '/h5/activity',
    component: () => import('@/pages/activity/h5/Index.vue'),
    meta: {
      layout: 'h5Default',
      // auth: 'login',
      title: t('活动'),
      ssg: {}
    },
    redirect: '/h5/activity/api-customer-rewards',
    children: [
      // {
      //   path: 'user-tasks',
      //   component: () => import('@/pages/activity/h5/NewUserTasks.vue'),
      //   meta: {
      //     class: {
      //       layout: 'NewUserTasks-box',
      //       header: 'NewUserTasks-header'
      //     },
      //     // auth: 'login',
      //     props: { header: { border: false } },
      //     title: t('邀请好友')
      //   }
      // },
      {
        path: 'api-customer-rewards',
        component: () => import('@/pages/api-activity/h5/ApiCustomerRewards.vue'),
        meta: {
          layout: '',
          props: { header: { border: false } },
          title: t('API客户奖励'),
          ssg: {}
        }
      },
      {
        path: 'celebration',
        component: () => import('@/pages/api-activity/h5/Celebration.vue'),
        meta: {
          layout: 'default',
          props: { header: { border: false } },
          title: t('专业投资者认证 - 奖励500 HSK - HashKey Exchange - 买币更方便，存币更安心'),
          description: t('欢迎专业投资者踏上 HSK 开户之路！活动期间提交并完成专业投资者认证即获得 500 HSK！'),
          ssg: {}
        }
      }, {
        path: 'mbox',
        component: () => import('@/pages/activity/mbox/h5/index.vue'),
        meta: {
          class: {
            layout: 'NewUserTasks-box',
            header: 'NewUserTasks-header'
          },
          // auth: 'login',
          props: { header: { border: false } },
          title: t('HSK 盲盒'),
          ssg: {}
        }
      },
      {
        path: 'visitpc',
        component: () => import('@/pages/activity/visitpc/h5/index.vue'),
        meta: {
          class: {
            layout: 'NewUserTasks-box',
            header: 'NewUserTasks-header'
          },
          props: { header: { border: true } },
          title: '',
          ssg: {}
        }
      },
      {
        path: 'mb412',
        component: () => import('@/pages/activity/h5/MB412.vue'),
        meta: {
          class: {
          },
          // auth: 'login',
          props: { header: { border: false } },
          title: t('fs-2023 香港 Web3 Festival 盲盒'),
          ssg: {}
        }
      },
      {
        path: 'ZACard',
        component: () => import('@/pages/activity/h5/zaCard/Index.vue'),
        meta: {
          class: {
          },
          // auth: 'login',
          props: { header: { border: false } },
          title: t('ZA 虚拟卡福利'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/ZACard' : ''
      },
      {
        path: 'genesisVip',
        component: () => import('@/pages/activity/h5/vip/Index.vue'),
        meta: {
          layout: 'h5Default',
          title: t('创世 VIP')
          // ssg: {}
        },
        redirect: isPC() ? '/activity/genesisVip' : ''
      },
      {
        path: 'genesisVip/result/:roundId',
        component: () => import('@/pages/activity/h5/vip/Result.vue'),
        meta: {
          layout: 'h5Default',
          title: t('创世 VIP'),
          ssg: {}
        }
      },
      {
        path: 'genesisVip/mine',
        component: () => import('@/pages/activity/h5/vip/Mine.vue'),
        meta: {
          layout: 'h5Default',
          title: t('我的卡片'),
          ssg: {}
        }
      },
      {
        path: 'genesisVip/card',
        component: () => import('@/pages/activity/h5/vip/Card.vue'),
        meta: {
          layout: 'h5Default',
          title: '',
          ssg: {}
        }
      },
      {
        path: 'LunarNewYearTrading',
        component: () => import('@/pages/activity/h5/newYearTradeHsk/index.vue'),
        meta: {
          class: {},
          props: { header: { border: false } },
          title: t('trade-home-2024-page-title龙礼盛典交易赛'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/LunarNewYearTrading' : ''
      },
      {
        path: 'tradeGiveHsk',
        component: () => import('@/pages/activity/tradeGiveHsk/h5/Index.vue'),
        meta: {
          class: {},
          props: { header: { border: false } },
          title: t('tradeGiveHsk.activityDetail.title'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/tradeGiveHsk' : ''
      }
    ]
  }
];

export default activity;
