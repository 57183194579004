<template>
  <el-dialog class="hk-dialog" v-bind="attrs">
    <template v-for="slot in slotsKeys" #[slot]>
      <slot :name="slot" />
    </template>
  </el-dialog>
</template>

<script lang="ts">
// namespaced components
export default {
  name: 'HkDialog'
};
</script>

<script lang="ts" setup>
const attrs = useAttrs();
const slots = useSlots();
const slotsKeys = Object.keys(slots);
// const props = defineProps();    
</script>

<style lang="scss">
</style>