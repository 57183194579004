/* eslint-disable*/

/**
 * @license
 * @version v0.0.1
 * Released under the BSD-3-Clause license.
 */import n from 'bignumber.js'; import { default as O } from 'bignumber.js';

 n.set({ DECIMAL_PLACES: 30, EXPONENTIAL_AT: 30, ROUNDING_MODE: 1 }); const $ = (e) => { const t = n.isBigNumber(e) ? e.toFixed() : String(e); return /[a-z]/i.test(t) ? '0' : t; }; const p = (e, t) => $(new n(`${e}`).plus(`${t}`)); const a = (e, t) => $(new n(`${e}`).minus(`${t}`)); const l = (e, t) => $(new n(`${e}`).times(`${t}`)); const w = (e, t) => (`${t}` == '0' ? '0' : $(new n(`${e}`).div(`${t}`))); const c = (e, t) => (`${t}` == '0' ? '0' : $(new n(`${e}`).idiv(`${t}`))); const f = (e, t) => new n(`${e}`).eq(`${t}`); const g = (e, t) => new n(`${e}`).gt(`${t}`); const b = (e, t) => new n(`${e}`).gte(`${t}`); const x = (e, t) => new n(`${e}`).lt(`${t}`); const D = (e, t) => new n(`${e}`).lte(`${t}`); const s = (e, t, r) => { const o = typeof t === 'number' ? new n(`${e}`).toFixed(t, r) : new n(`${e}`).toFixed(); return $(o); }; const F = (e, t, r) => { const o = typeof t === 'number' ? new n(`${e}`).sd(t, r) : new n(`${e}`).sd(); return $(o); }; const m = (e, t, r, o) => $(new n(`${e}`).toFormat(t, r, o)); const N = (e, t, r) => { let o = String(e); const u = typeof t === 'number' ? t : 2; const d = ['', 'K', 'M', 'B', 'T', 'Qa', 'Qi', 'Sx', 'Sp', 'Oc', 'No', 'Dc', 'UDc', 'DDc']; const i = Math.floor((s(o, 0).length - 1) / 3); return o = w(o, 10 ** (i * 3)), o = typeof r === 'number' ? m(o, u, r) : m(o, u), o === '0' ? '0' : `${o}${i > 0 ? ` ${d[i]}` : ''}`; }; const v = (...e) => $(n.sum(...e)); const E = (e) => { let t = e; return t === void 0 && (t = 32), s($(n.random(t)), t); }; const S = (e) => $(new n(e).abs()); export {
  S as abs, O as default, w as div, f as eq, g as gt, b as gte, c as idiv, x as lt, D as lte, a as minus, p as plus, E as random, F as sd, v as sum, l as times, s as toFixed, m as toFormat, N as toFormatUnit,
};
/* eslint-enable */

