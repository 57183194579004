// TODO: 去掉 isInApp APP 提供的方法
// TODO: 浏览器唤醒 APP 有更复杂的处理时机，目前先这样

import { appConfig } from '@/config/setup';

// ----------------------------------------> 常量和变量（仅暴露 isApp 和 appVersion） Start
const { APP_COMMON_PREFIX } = appConfig;

// 跳转下载页面的时机
const DOWN_LOAD_COUNT_DOWN = 4500;

// APP UserAgent 信息
const appUA = navigator.userAgent.match(/HashKey(?:\s[\w]+)?\/((?:\d+\.?)+)/);

// 是否是 APP
export const isApp = /HashKey/.test(navigator.userAgent);

// APP 版本好
export const appVersion = isApp ? (appUA as RegExpMatchArray)[1] : '';
// ----------------------------------------> 常量和变量（仅暴露 isApp 和 appVersion） End

// ----------------------------------------> 基础方法（不对外暴露） Start
// 浏览器唤醒 APP -- 通用方法
let timer: number;
const awakenApp = (url: string) => new Promise<void>((resolve, reject) => {
  if (url === '') {
    throw new Error('url 不能为空字符串');
  }

  const cb = () => {
    if (document.hidden) {
      clearTimeout(timer);
      document.removeEventListener('visibilitychange', cb, false);
      resolve();
    }
  };

  timer = setTimeout(() => {
    window.location.href = '/h5/download';
    document.removeEventListener('visibilitychange', cb, false);
    reject();
  }, DOWN_LOAD_COUNT_DOWN);

  // 这里使用 document.addEventListener, 而不是 window.addEventListener，因为 Safari < 14.0 仅支持前者。
  document.addEventListener('visibilitychange', cb, false);

  window.location.href = url;
});

// 统一调用 APP Bridge 的方法
const commonCallHandler = (methodName: string, params = {}) => {
  return window.flutter_inappwebview.callHandler(methodName, params);
};

// APP 打开页面
const appOpenPage = (url: string, needReload = true) => {
  return commonCallHandler('JumpFlutter', { url, needReload });
};

// APP 调用方法
const callNativeFunction = (options = {}) => {
  return commonCallHandler('callNative', options);
};

// APP 内打开原生页面
const openNativePage = (deepLink: string, needReload = true) => {
  return appOpenPage(`${APP_COMMON_PREFIX}/${deepLink}`, needReload);
};
// ----------------------------------------> 基础方法（不对外暴露） End

// ----------------------------------------> 对外暴露的方法 Start
// 浏览器内唤醒 APP 原生页面
export const awakenAppNative = (deepLink: string) => awakenApp(
  `${APP_COMMON_PREFIX}/${deepLink}`
);

// 浏览器唤醒 APP 内的 webview 页面
export const awakenAppWebview = (url?: string, needReload = true) => awakenApp(
  `${APP_COMMON_PREFIX}/webview?url=${url || window.location.href}&needReload=${needReload}`
);

// APP 内打开原生浏览器
export const openNativeBrowser = (url: string) => {
  return commonCallHandler('JumpFlutter', { url: `${APP_COMMON_PREFIX}/system_webview?url=${url}` });
};

// APP 使用 webview 打开 web 页面
export const openWebviewPage = (url: string, needReload = true) => {
  return appOpenPage(url, needReload);
};

// APP 打开 原生 KYC 页面
export const openKycPage = () => openNativePage('kyc_page');

// APP 打开原生登录页面
export const openLoginPage = () => openNativePage('login');

// APP 打开邀请历史页面
export const openInviteHistoryPage = () => openNativePage('invite_history');

// APP 打开合约页面
export const openContractPage = (symbolId: string) => openNativePage(`contract?symbolId=${symbolId}`);

// APP 打开首页页面
export const openHomePage = () => openNativePage('home');

// APP 打开现货页面
export const openSpotPage = (symbolId: string) => openNativePage(`spot?symbolId=${symbolId}`);

// APP 打开合约页面
export const openFuturesPage = (symbolId: string) => openNativePage(`contract?symbolId=${symbolId}`);

// App 打开 合约订单页面 合约交易默认tab
export const openFutruesOrderPage = () => openNativePage('contract_order_list');

// APP 打开注册页
export const openRegisterPage = () => openNativePage('register');

// APP 打开 HSK 积分页面
export const openHskScorePage = () => openNativePage('hsk_score_page');

// APP 打开 Copy Trading 页面
export const openCopyTradePage = () => openNativePage('copytrading');

// APP 打开 充值 页面
export const openDepositPage = (coin: string) => openNativePage(`deposit?tokenId=${coin.toLocaleUpperCase()}`);

// APP 打开划转页面
export const openTransferPage = (type: string, tokenId: string) => openNativePage(`transfer2?type=${type}&tokenId=${tokenId}`);

// APP 调用分享
export const callAppShare = (imgBase64: string) => openNativePage(`share?image=${imgBase64}`, false);

// APP 调用拷贝
export const callAppCopy = (text: string) => openNativePage(`copy?text=${text}`);

// APP 获取用户信息
export const callUserInfo = () => callNativeFunction({ methodName: 'getUserInfo' });

// APP 获取用户登录态
export const callLoginInfo = () => callNativeFunction({ methodName: 'isLogin' });

// APP 获取用户 KYC 信息
export const callKycInfo = () => callNativeFunction({ methodName: 'isKycAuthed' });

// APP 获取用户当前登录是否为账户  1：子账户 0：主账户
export const callSubAccount = () => callNativeFunction({ methodName: 'isSubAccount' });

//  App 获取当前 App 是否支持代理返佣 1: 支持，其他 不支持
export const callAppHasReferralAbility = () => callNativeFunction({ methodName: 'appHasReferralAbility' });

// APP 获取当前 APP 是否支持跳转合约订单页面，true 支持，其他 不支持
export const callSupportFuturesOrderList = () => callNativeFunction({ methodName: 'supportContractOrderList' });

// APP 设置页面标题
export const callPageTitle = (title: string) => commonCallHandler('showTitle', { title });

// APP 添加日历提醒
type CalendarParams = {
  title: string,
  startTime: string,
  endTime?: string,
  awaitTime?: number
}
export const callAddCalendar = (params: CalendarParams) => callNativeFunction({ methodName: 'add_calendar_event', params });

// APP 启动极限验证
export const callStartGeeTestVerify = () => callNativeFunction({ methodName: 'startGeeTestVerify' });

// APP 打开关闭当前 h5 页面
export const callClosePage = () => openNativePage('close');

// APP 关闭所有 h5 页面
export const callCloseAllPage = () => commonCallHandler('closeAll');

// APP 调用 httpRequest
export const callHttpRequest = (options = {}) => {
  return commonCallHandler('httpRequest', options);
};

// APP 调用 httpRequest 进一步封装
type HROptions = {
  showLoading?: boolean
}
const callHttpRequestWrap = (method: 'POST' | 'GET', url: string, params: any = {}, options: HROptions = { showLoading: false }) => callHttpRequest({ 
  method,
  url: /^@/.test(url) ? url.replace(/^@/, '/') : url,
  params: params ?? {},
  ...options 
}).then((res: Record<string, unknown>) => ({
  ...res,
  success: res.code === 200
}));
export const req = {
  get: (url: string, params?: any, option?: HROptions) => callHttpRequestWrap('GET', url, params, option),
  post: (url: string, data?: any, option?: HROptions) => callHttpRequestWrap('POST', url, data, option)
};

// App 调用是否适配本地时区
export const callSupportTimezoneAdjustment = () => callNativeFunction({ methodName: 'supportTimezoneAdjustment' });

// ----------------------------------------> 对外暴露的方法 End
