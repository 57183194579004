<template>
  <el-autocomplete>
    <slot />
  </el-autocomplete>
</template>

<script lang="ts">
// namespaced components
export default {
  name: 'HkAutocomplete'
};
</script>

<script lang="ts" setup>
// const props = defineProps();
</script>

<style lang="scss" scoped>
</style>