<template>
  <hk-input
    v-model="verifyCode"
    :placeholder="placeholder"
    :maxlength="maxLength"
    type="text"
    class="input-lg"
  >
    <template #suffix>
      <!-- 未发送、重发 -->
      <span
        v-if="['unsent', 're-sent'].includes(sendCodeStatus)"
        class="send-code-btn text-sm-m primary-500"
        :class="{ disabled: disabled }"
        @click="sendVerifyCode"
      >{{ sendBtnText }}</span>
      <!-- 已发送 -->
      <template v-if="sendCodeStatus === 'success'">
        <span class="send-code-btn disabled">{{ countdownTime }}s</span>
        <!-- <el-tooltip
                    placement="top"
                    popper-class="send-code-popper"
                >
                    <template #content>{{ tipText }}</template>
                    <i class="send-code-tip" />
                </el-tooltip> -->
      </template>
    </template>
  </hk-input>
  <Captcha ref="captcha" @captcha-result="captchaResult" />
</template>

<script setup lang="ts">
import { isNumber } from '@/utils/tools';
import { ref, computed, defineEmits, defineProps, onBeforeUnmount, defineExpose } from 'vue';
import Captcha from '@c/common/Captcha.vue';
const { t } = useI18n();
const emit = defineEmits(['update:modelValue', 'send-code']);

// 扩展参数根据需求补充
const props = defineProps({
  // 绑定验证码
  modelValue: String,
  // 重发倒计时
  countdown: {
    type: Number,
    default: 60
  },
  // 发送按钮文案（TODO）
  sendText: Object,
  // 验证码过期时间（分钟）
  expire: {
    type: String,
    default: '30'
  },
  // 验证码提示文案
  sendTipText: {
    type: String,
    default: '没收到验证码？{second} 秒后可重新发送，验证码 {expire} 分钟后过期。'
  },
  // 禁止发送状态-初始值
  disabled: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: ''
  },
  maxLength: {
    type: Number,
    default: 6
  },
  needCaptch: {
    type: Boolean,
    default: false
  }
});
// 验证码
const verifyCode = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    // 限制只能输入数字
    let v = isNumber(value);
    emit('update:modelValue', v);
  }
});

/**
 * 验证码发送状态
 *
 * unsent: 未发送
 * success: 已发送
 * re-sent: 重发
 */
const sendCodeStatus = ref('unsent');

// 发送按钮文案
const sendBtnText = computed(() => {
  return {
    unsent: t('发送'),
    success: t('已发送'),
    're-sent': t('重新发送')
  }[sendCodeStatus.value];
});

// 提示文案
// const tipText = computed(() => {
//   let text = props.sendTipText;
//   text = text.replace('{expire}', props.expire);
//   text = text.replace('{second}', String(countdownTime.value));
//   return text;
// });

// 倒计时秒数
const COUNT_DOWN_NUM: number = props.countdown || 0;

// 倒计时
const countdownTime = ref(COUNT_DOWN_NUM);

// 计时器
let timer: any = null;

// 重置状态
const reset = () => {
  clearTimeout(timer);
  countdownTime.value = COUNT_DOWN_NUM;
  sendCodeStatus.value = 'unsent';
};

// 更新验证码发送状态
const updateSendStatus = () => {
  if (countdownTime.value > 0) {
    timer = setTimeout(() => {
      countdownTime.value -= 1;
      updateSendStatus();
    }, 1000);
  } else {
    sendCodeStatus.value = 're-sent';
    countdownTime.value = COUNT_DOWN_NUM;
  }
};
// 发送成功回调
const successCallback = () => {
  sendCodeStatus.value = 'success';
  updateSendStatus();
};
// 发送失败回调
const errorCallback = () => {
  // TODO
};

// 手机验证码触发极验
const captcha = ref();
const captchaRes = ref(); // 极验的返回结果
const captchaResult = (result: object) => {
  if (result) {
    emit('send-code', successCallback, errorCallback, result);
  }
};

// 发送验证码
const sendVerifyCode = (event: any) => {
  if (!props.disabled) {
    // h5 防止穿透弹出键盘
    event.stopPropagation();
    
    if (props.needCaptch) {
      captcha.value.showBox();
    } else {
      emit('send-code', successCallback, errorCallback);
    }
  }
};

// 销毁前清除定时器
onBeforeUnmount(() => {
  clearTimeout(timer);
});

defineExpose({ reset });
</script>

<style lang="scss" scoped>
:deep(.el-input__wrapper) {
  padding-right: 16px;
}

.send-code-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary);
  cursor: pointer;

  &.disabled {
    color: #9aa5b5;
    cursor: not-allowed;
  }
}

.send-code-tip {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  background: url(@/assets/img/icon/icon-send-code-tip.png) center center/100% 100% no-repeat;
}
</style>

<style lang="scss">
.el-popper.send-code-popper {
  max-width: 240px;
  padding: 5px 8px;
  background-color: var(--text-icon);
  border-radius: 4px;
  border-color: var(--text-icon);
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  text-align: center;

  .el-popper__arrow::before {
    background: var(--text-icon);
    border-color: var(--text-icon);
  }
}
</style>
