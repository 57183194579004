<script setup lang="ts">
import { useTitle } from '@vueuse/core';
import { useWsStore } from '@/store/ws';
import { useCommonStore } from '@/store/common';
import { resizeObserver, loadScript } from '@/utils/tools';
import { removeIPQSCookie } from '@/utils/cookie';
import Layout from '@/components/layout/Layout.vue';
import { useLayoutStore } from '@/store/layout';
import { usePopstate } from '@/hook/usePopstate';
import { userApi } from '@/config/api';
import Loading from '@/components/common/Loading.vue';
import CONST from '@/config/const';
import { initializeApp } from 'firebase/app';
import { getRemoteConfig, getValue, fetchAndActivate } from 'firebase/remote-config';
import DeviceFingerprint from "node_js_ipqs_device_tracker";

if (!import.meta.env.SSR) {
  const popstate = usePopstate();
  popstate.stateChange((e: any) => {
    const { scroll } = e.state;
    const left = scroll?.left || 0;
    const top = scroll?.top || 0;

    document.documentElement.scrollTo(left, top);
    document.body.scrollTo(left, top);
  });
}

const route = useRoute();
const common = useCommonStore();
const wsStore = useWsStore();
const layoutStore = useLayoutStore();
const pageTitle = useTitle();
const { t } = useI18n();

const isLoadingShow = ref(false);

const genFingerPrint = () => {
  const startTime = Date.now();
  DeviceFingerprint.initializeScriptAsync(CONST.ipqsSecretKey)
    .then(async () => {
      // 成功
      // 清楚冗余的device_id相关的cookie
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        if (name.includes('device_id_')) {
          removeIPQSCookie(name.replace(' ', ''));
        }
      }
      
      DeviceFingerprint.AfterResult((result: any) => {
        // ipqs获取数据成功
        localStorage.setItem('device-info', JSON.stringify({
          'device_id': result?.device_id || '',
          'device_name': result?.operating_system || '',
          'device_model': result?.browser || ''
        }));
        // 向服务器发送ipqs信息
        userApi.submitIPQS(result);
        // ipqs耗时
        const endTime = Date.now();
        // userApi.submitRisk({
        //   bizType: 'ipqsCostTime',
        //   params: JSON.stringify({
        //     requestId: result?.request_id || `s_${endTime}${Math.random().toString(32).substr(-4, 4)}`,
        //     reqTimestamp: startTime,
        //     resTimestamp: endTime,
        //     costTime: endTime - startTime
        //   })
        // });
      });
      DeviceFingerprint.Init();
    }).catch((err) => {
      // 失败
      // ipqs耗时
      const endTime = Date.now();
      // userApi.submitRisk({
      //   bizType: 'ipqsCostTime',
      //   params: JSON.stringify({
      //     requestId: `f_${endTime}${Math.random().toString(32).substr(-4, 4)}`,
      //     reqTimestamp: startTime,
      //     resTimestamp: endTime,
      //     costTime: endTime - startTime
      //   })
      // });
    });
};

onBeforeMount(() => {
  // 加载完成去除全局的loading
  const openScreenLoadingDom: HTMLElement | null = window?.document?.querySelector('#open-screen-loading');
  if (openScreenLoadingDom) {
    openScreenLoadingDom.style.display = 'none';
    document.body.style.backgroundColor = 'transparent';
  }
});

onMounted(() => {
  // 加载完成去除全局的loading
  const openScreenLoadingDom: HTMLElement | null = window?.document?.querySelector('#open-screen-loading');
  if (openScreenLoadingDom) {
    openScreenLoadingDom.style.display = 'none';
    document.body.style.backgroundColor = 'transparent';
  }

  resizeObserver((entires: any) => {
    if (entires?.[0]?.contentRect) {
      common.isMobile = entires[0].contentRect.width;
      if (entires[0].contentRect.width < 800) {
        document.documentElement.setAttribute('m', 'true');
      } else {
        document.documentElement.setAttribute('m', 'false');
      }
    }
  }).observe(document.body);

  // 红涨绿跌
  if (localStorage.getItem('upDownColor') === 'dark') {
    document.documentElement.setAttribute('data-theme', 'dark');
  }
  // ipqs
  genFingerPrint();

  // 前端性能埋点统计
  if(import.meta.env.MODE == 'production') {
    try {
      // 首次渲染时间(白屏时间)
      var FCP = parseInt(performance.getEntriesByType('paint')[1].startTime, 10);
      console.log('FCP', FCP);
      if (FCP < 5000) {
        gtag('event', 'webPerformance-FCP', { value: FCP });
      }
      // 首屏渲染时间
      new PerformanceObserver(entryList => {
        for (const entry of entryList.getEntries()) {
          var LCP = parseInt(entry.startTime, 10);
          console.log('LCP', LCP, entry);
          if (LCP < 5000) {
            gtag('event', 'webPerformance-LCP', { value: LCP });
          }
        }
      }).observe({ type: 'largest-contentful-paint', buffered: true });
      // 页面可交互时间
      var TTI = parseInt(performance.timing.domContentLoadedEventEnd - performance.timing.navigationStart, 10);
      console.log('TTI', TTI);
      if (TTI > 0 && TTI < 10000) {
        gtag('event', 'webPerformance-TTI', { value: TTI });
      }
      // 首次交互延迟
      new PerformanceObserver(function(list, obs) {
        const firstInput = list.getEntries()[0];

        // Measure the delay to begin processing the first input event.
        const FID = parseInt(firstInput.processingStart - firstInput.startTime, 10);
        // Measure the duration of processing the first input event.
        // Only use when the important event handling work is done synchronously in the handlers.
        const firstInputDuration = firstInput.duration;
        // Obtain some information about the target of this event, such as the id.
        const targetId = firstInput.target ? firstInput.target.id : 'unknown-target';
        // Process the first input delay and perhaps its duration...

        // Disconnect this observer since callback is only triggered once.
        console.log('FID', FID);
        gtag('event', 'webPerformance-FID', { value: FID });
        obs.disconnect();
      }).observe({ type: 'first-input', buffered: true });
    } catch (e) { }
  }
});

// 初始化firebase
const firebaseConfig = {
  apiKey: 'AIzaSyBPkwaf_3rRxuj7vOTGXEcly2r1ZfvoVIk',
  authDomain: 'hashkey-exchange.firebaseapp.com',
  projectId: 'hashkey-exchange',
  storageBucket: 'hashkey-exchange.appspot.com',
  messagingSenderId: '912725861131',
  appId: '1:912725861131:web:5800d002c368dac39ef80e',
  measurementId: 'G-DKW24D00M3'
};
const app = initializeApp(firebaseConfig);
// Initialize Remote Config and get a reference to the service
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 1000;
remoteConfig.defaultConfig = {
  'feature_travel_rule': false  // 控制travelRule
};
fetchAndActivate(remoteConfig)
  .then(() => {
    const feature_travel_rule_web = getValue(remoteConfig, 'feature_travel_rule_web');
    localStorage.setItem('feature_travel_rule_web', feature_travel_rule_web._value);
  })
  .catch(err => {
  // ...
  });

watchEffect(() => {
  if (route.query.f) {
    localStorage.setItem('f', `${route.query.f}`);
  }
});

watch(() => layoutStore.pageTitle, (title: string) => {
  pageTitle.value = title ? `${t(title as string)}` : t('HashKey Exchange-买币更方便，存币更安心');
}, { immediate: true });

watch((): boolean => common.isLoading,
  isLoading => {
    isLoadingShow.value = isLoading;
  }, { immediate: true });

// 行情 提前建连 临时方案(后续考虑放入 路由beforeEach)
wsStore.createWS();
</script>

<template>
  <div v-if="isLoadingShow" class="loadingwrapper">
    <Loading width="100vw" height="100vh" class="loading" />
  </div>
  <Layout v-if="route.meta.ssg" />
  <client-only v-else>
    <Layout />
  </client-only>
</template>

<style lang="scss">
@import 'element-plus/theme-chalk/dark/css-vars.css';
@import '@/assets/styles/entry';
</style>
<style lang="scss" scoped>
.loadingwrapper {
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 999;
}
</style>