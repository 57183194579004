/**
 * @file 统一全局顶部 Toast 提示
 * @date 2022-10-14
 */

import { ElMessage } from 'element-plus';
import type { MessageParamsWithType, MessageHandler } from 'element-plus';
import { useAppStore } from '@/store/app';
import { isPC } from '@/utils/tools';
// import type { DefineComponent } from 'vue';

export type Toast = {
    [propname: string]: (arg: string | MessageParamsWithType) => MessageHandler
}

// icon components
// const iconMap: Record<string, DefineComponent> = {
//     info: warning,
//     success,
//     warning,
//     error,
// };

// 生成options
const generateOptions = (type: string, opt: string | MessageParamsWithType): MessageParamsWithType => {
  const options = typeof opt === 'string' ? { message: opt, type } : { ...opt, type };

  return options;
};
// customClass 可以主动控制黑白模式，默认根据主题色 dark light
// toast.success({message:'提示文案', customClass:'dark or(light)'})
export const toast: Toast = {
  // 一般提示和自定义消息
  info(opt) {

    const appStore: any = useAppStore();
    const options: Record<string, any> = typeof opt === 'object' ? opt : { message: opt };
    const offset = isPC() ? 16 : appStore.jsbridgeStatus ? 16 : 64;
    console.log(offset, appStore.jsbridgeStatus, isPC);

    if (!options.type) options.type = 'info';

    return ElMessage({
      // icon: iconMap[options.type],
      dangerouslyUseHTMLString: true,
      grouping: true,
      offset: offset,
      ...options,
      customClass: `toast-message toast-${options.type} ${options.customClass ? options.customClass : ''}`
    });
  },
  // 成功提示
  success(opt) {
    console.log(opt);
    return toast.info(generateOptions('success', opt));
  },
  // 警告提示
  warning(opt) {
    console.log(opt);
    return toast.info(generateOptions('warning', opt));
  },
  // 成功提示
  error(opt) {
    console.log(opt);
    return toast.info(generateOptions('error', opt));
  }
};
