// eslint-disable-next-line
// @ts-nocheck
import { i18n } from '@/lang/i18n';
import { pinia } from '@/store';
import { useCommonStore } from '@/store/common';
import { isPC } from '@/utils/tools';

const { t } = i18n.global;
const commonStore = useCommonStore(pinia);

// 爬虫不爬的路由 8个
const noSEO = [
  {
    path: '/forbidden/ip',
    component: () => import('@/pages/forbidden/Index.vue'),
    meta: {
      layout: 'default',
      title: t('限制访问')
    },
    redirect: !isPC() ? '/h5/forbidden/ip' : ''
  },
  {
    path: '/forbidden/vpn',
    component: () => import('@/pages/forbidden/Index.vue'),
    meta: {
      layout: 'default',
      title: t('限制访问')
    },
    redirect: !isPC() ? '/h5/forbidden/vpn' : ''
  },
  {
    path: '/forbidden/area',
    component: () => import('@/pages/forbidden/Index.vue'),
    meta: {
      layout: 'default',
      title: t('限制访问')
    },
    redirect: !isPC() ? '/h5/forbidden/area' : ''
  },
  {
    path: '/h5/forbidden/ip',
    component: () => import('@/pages/forbidden/h5/Index.vue'),
    meta: {
      layout: 'h5Default',
      title: t('限制访问')
    }
  },
  {
    path: '/h5/forbidden/vpn',
    component: () => import('@/pages/forbidden/h5/Index.vue'),
    meta: {
      layout: 'h5Default',
      title: t('限制访问')
    }
  },
  {
    path: '/h5/forbidden/area',
    component: () => import('@/pages/forbidden/h5/Index.vue'),
    meta: {
      layout: 'h5Default',
      title: t('限制访问')
    }
  },
  {
    path: '/404/',
    component: () => import('@/pages/noRouter/404.vue'),
    meta: {
      layout: 'default',
      class: {
        footer: 'hidden'
      }
    },
    redirect: !isPC() ? '/h5/404/' : ''
  },
  {
    path: '/h5/404/',
    component: () => import('@/pages/noRouter/h5/404.vue'),
    meta: {
      layout: 'h5Default',
      title: ''
    }
  }
];

export default noSEO;