// eslint-disable-next-line
// @ts-nocheck
import { i18n } from '@/lang/i18n';
import { pinia } from '@/store';
import { useCommonStore } from '@/store/common';
import { isPC } from '@/utils/tools';

const { t } = i18n.global;
const commonStore = useCommonStore(pinia);

// 交易路由 7个
const trade = [
  {
    path: '/spot/:symbolPath',
    component: () => import('@/pages/spot/Index.vue'),
    meta: {
      layout: 'exchange',
      theme: true,
      title: t('现货交易 - HashKey Exchange'),
      description: t('使用法币（美元USD，港币HKD）或虚拟资产（比特币BTC，以太坊ETH）进行充值、提现和交易，享受低延迟、高流动性和优惠手续费的优质体验。'),
      props: { header: { border: false } },
      ssg: {}
    }
  },
  {
    path: '/spot',
    component: () => import('@/pages/spot/Index.vue'),
    meta: {
      layout: 'exchange',
      theme: true,
      title: t('现货交易 - HashKey Exchange'),
      description: t('使用法币（美元USD，港币HKD）或虚拟资产（比特币BTC，以太坊ETH）进行充值、提现和交易，享受低延迟、高流动性和优惠手续费的优质体验。'),
      props: { header: { border: false } },
      ssg: {}
    }
  },
  {
    path: '/order',
    component: () => import('@/pages/order/Index.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('订单')
    }
  },
  {
    path: '/transfer/details',
    component: () => import('@/pages/order/TransferDetails.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('划转明细')
      // ssg: {}
    }
  },
  {
    path: '/markets',
    component: () => import('@/pages/markets/Index.vue'),
    meta: {
      layout: 'default',
      title: t('行情总览 - 加密货币价格指数、实时图表和法币兑换 - HashKey Exchange - 买币更方便，存币更安心'),
      description: t('在HashKey交易所，您可以享受全球最优质的数字资产交易服务。无论何时何地，您都可以获取最新的行情图表、行业新闻和数字货币价格。您还可以根据自己的喜好选择交易对，并实时查看您的交易历史和价格变化。'),
      ssg: {
        preload: [commonStore.getSymbols, commonStore.getCurrency]
      }
    }
  },
  {
    path: '/price/:symbolPath',
    component: () => import('@/pages/price/Index.vue'),
    meta: {
      title: t('加密货币价格指数、实时图表和法币兑换 - HashKey Exchange - 买币更方便，存币更安心'),
      description: t('了解加密货币价格指数、实时图表和法币兑换价格。'),
      layout: 'default',
      ssg: {}
    }
  },
  {
    path: '/price',
    component: () => import('@/pages/price/Index.vue'),
    meta: {
      title: t('加密货币价格指数、实时图表和法币兑换 - HashKey Exchange - 买币更方便，存币更安心'),
      layout: 'default',
      description: t('了解加密货币价格指数、实时图表和法币兑换价格。'),
      ssg: {}
    }
  },
  {
    path: '/epeth/:symbolPath',
    component: () => import('@/pages/epeth/Index.vue'),
    meta: {
      title: t('加密货币价格指数、实时图表和法币兑换 - HashKey Exchange - 买币更方便，存币更安心'),
      description: t('了解加密货币价格指数、实时图表和法币兑换价格。'),
      layout: 'default',
      ssg: {}
    }
  },
  {
    path: '/epeth',
    component: () => import('@/pages/epeth/Index.vue'),
    meta: {
      title: t('加密货币价格指数、实时图表和法币兑换 - HashKey Exchange - 买币更方便，存币更安心'),
      layout: 'default',
      description: t('了解加密货币价格指数、实时图表和法币兑换价格。'),
      ssg: {}
    }
  }
];

export default trade;
