let workerTimer = {};
try {
  // web worker
  const blobURL = URL.createObjectURL(
    new Blob(
      [
        '(',
        function() {
          const intervalIds = {};
          // 监听message 开始执行定时器或者销毁
          self.onmessage = function onMsgFunc(e) {
            switch (e.data.command) {
              case 'interval:start': // 开启定时器
              // eslint-disable-next-line no-case-declarations
                const intervalId = setInterval(() => {
                  postMessage({
                    message: 'interval:tick',
                    id: e.data.id
                  });
                }, e.data.interval);
  
                postMessage({
                  message: 'interval:started',
                  id: e.data.id
                });
                intervalIds[e.data.id] = intervalId;
                break;
              case 'interval:clear': // 销毁
                clearInterval(intervalIds[e.data.id]);
                postMessage({
                  message: 'interval:cleared',
                  id: e.data.id
                });
                delete intervalIds[e.data.id];
                break;
              default:
                break;
            }
          };
        }.toString(),
        ')()'
      ],
      { type: 'application/javascript' }
    )
  );
  const worker = new Worker(blobURL);
  URL.revokeObjectURL(blobURL);
  workerTimer = {
    id: 0,
    callbacks: {},
    setInterval(cb, interval, context) {
      this.id = +this.id + 1;
      const { id } = this;
      this.callbacks[id] = { fn: cb, context };
      worker.postMessage({
        command: 'interval:start',
        interval,
        id
      });
      return id;
    },
    setTimeout(cb, timeout, context) {
      this.id = +this.id + 1;
      const { id } = this;
      this.callbacks[id] = { fn: cb, context };
      worker.postMessage({ command: 'timeout:start', timeout, id });
      return id;
    },
    // 监听worker 里面的定时器发送的message 然后执行回调函数
    onMessage(e) {
      switch (e.data.message) {
        case 'interval:tick':
        case 'timeout:tick': {
          const callbackItem = this.callbacks[e.data.id];
          if (callbackItem && callbackItem.fn)
            callbackItem.fn.apply(callbackItem.context);
          break;
        }
        case 'interval:cleared':
        case 'timeout:cleared':
          delete this.callbacks[e.data.id];
          break;
        default:
          break;
      }
    },
    // 往worker里面发送销毁指令
    clearInterval(id) {
      worker.postMessage({ command: 'interval:clear', id });
    },
    clearTimeout(id) {
      worker.postMessage({ command: 'timeout:clear', id });
    }
  };
  worker.onmessage = workerTimer.onMessage.bind(workerTimer);
} catch (error) {
  // window
  workerTimer = {
    setInterval: window.setInterval.bind(this),
    setTimeout: window.setTimeout.bind(this),
    clearInterval: window.clearInterval.bind(this),
    clearTimeout: window.clearTimeout.bind(this)
  };
}

export default workerTimer;
export const WorkerTimer = workerTimer;