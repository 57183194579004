<template>
  <el-popover>
    <slot />
  </el-popover>
</template>

<script lang="ts">
// namespaced components
export default {
  name: 'HkPopover'
};
</script>

<script lang="ts" setup>
// const props = defineProps();    
</script>

<style lang="scss" scoped>
</style>