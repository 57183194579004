import { defaultImg } from '@/directives/default-img';

// 拖拽的指令
const drag = {
  mounted(el: any, disConfig: any) {
    if (!disConfig.value.isDrag) {
      return;
    }

    // 可拖拽dom
    const dragDom = el.querySelector(disConfig.value.dragClass);

    const sty = (dom: any, attr: any) => {
      return getComputedStyle(dom, null)[attr];
    };

    dragDom.onmousedown = (e: any) => {
      const disX = e.clientX - dragDom.offsetLeft;
      const disY = e.clientY - dragDom.offsetTop;
      const styR = parseInt(sty(el, 'right'));
      const strT = parseInt(sty(el, 'top'));
      const rect = el.getBoundingClientRect();
      const areaDom = document.querySelector(disConfig.value.areaClass);
      const areaRect = areaDom.getBoundingClientRect();

      document.onmousemove = (moveE: any) => {
        const left = moveE.clientX - disX;
        const top = moveE.clientY - disY;

        // 顶部边界,底部边界
        if (top + strT < 0) {
          el.style.top = 0;
        } else if (top + strT > areaRect.height - rect.height) { // 48 是css上 height: calc(100% - 48px);
          el.style.top = `${areaRect.height - rect.height}px`;
        } else {
          el.style.top = `${top + strT}px`;
        }

        if (-left + styR < 0) {
          el.style.right = 0;
        } else if (-left + styR > areaRect.width - rect.width) {
          el.style.right = `${areaRect.width - rect.width}px`;
        } else {
          el.style.right = `${-left + styR}px`;
        }
      };

      document.onmouseup = function() {
        document.onmousemove = null;
        document.onmouseup = null;
      };

      return false;
    };

  }
};

// 挂载，注册
const directives = {
  install: function(app: any) {
    app.directive('dialogDrag', drag);
    app.directive('defaultImg', defaultImg);
  }
};
export default directives;
