/**
 * @file layout 状态
 */

// eslint-disable-next-line
// @ts-nocheck
export const useLayoutStore = defineStore('layoutStore', {
  state: () => ({
    // header悬浮定位
    headerFixed: false,
    // 当前路由布局名称
    routeLayout: 'default',
    // 当前route配置参数
    routeProps: <any>{},
    // 当前路由布局class名称
    layoutClass: <any>{},
    // iframe的链接
    iframeLink: '',
    // 页面标题
    pageTitle: '',
    // iframe传过来的message
    iframeMsg: null
  }),
  actions: {
    changeIframeMsg(msg: any) {
      this.iframeMsg = msg;
    },
    initRouteLayout(params: any) {
      if (params) {
        // if (params?.props?.header?.theme) params.props.header.theme = 'light'; // 第一期只有light 
        this.routeLayout = params.layout || 'default';
        this.routeProps = params.props || {};
        this.layoutClass = params?.class;
        this.iframeLink = params?.iframeLink;
      }

      // header默认固定
      this.headerFixed = false;
    }
  }
});
