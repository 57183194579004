// eslint-disable-next-line
// @ts-nocheck
import { i18n } from '@/lang/i18n';
// import { pinia } from '@/store';
// import { useCommonStore } from '@/store/common';
// import { isPC } from '@/utils/tools';

const { t } = i18n.global;
// const commonStore = useCommonStore(pinia);

// 存管路由 24个
const custody = [
  {
    path: '/custody/assetView', // 钱包首页(资产总览)
    component: () => import('@/pages/assets/Overview.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('资产总览'),
      ssg: {}
    }
  },
  {
    path: '/custody/assetView/securityDesc', // 资产安全保障说明页
    component: () => import('@/pages/assets/securityDescIndex.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('asset-security.link'),
      ssg: {}
    }
  },
  {
    path: '/custody/assetView/fundDetails', // 资产总览 - 资金明细 - new
    component: () => import('@/pages/assets/fund-details/Index.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('资金明细'),
      ssg: {}
    }
  },
  {
    path: '/custody/assetView/fundDetails-old', // 资产总览 - 资金明细  - 旧页面
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('资金明细'),
      iframeLink: '/web/exchange/custody/assetView/fundDetails'
    }
  },
  {
    path: '/custody/assetView/savingAccount', // 存管账户 - 账户概览
    component: () => import('@/pages/assets/custody/Custody.vue'),
    redirect: '/custody/assetView/savingAccount/detail',
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('账户概览'),
      ssg: {}
    },
    children: [
      {
        path: 'detail',
        component: () => import('@/pages/assets/custody/components/AccountDetail.vue'),
        title: t('账户概览'),
        ssg: {}
      },
      {
        path: 'record',
        component: () => import('@/pages/assets/custody/components/AccountRecord.vue'),
        title: t('账户记录'),
        ssg: {}
      },
      {
        path: 'whiteList',
        component: () => import('@/pages/assets/custody/whiteList/WhiteListIndex.vue'),
        title: t('白名单管理'),
        ssg: {}
      }
    ]
  },
  {
    path: '/custody/assetView/savingAccount/deposit', // 存管账户 - 充值 (vue3)
    component: () => import('@/pages/assets/custody/deposit/Index.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('充值'),
      ssg: {}
    }
  },
  // {
  //   path: '/custody/assetView/savingAccount/deposit-old', // 存管账户 - 充值(react)
  //   meta: {
  //     layout: 'default',
  //     auth: 'login',
  //     title: t('充值'),
  //     iframeLink: '/web/exchange/custody/assetView/savingAccount/deposit'
  //   }
  // },
  {
    path: '/custody/assetView/savingAccount/deposit/addressVerify-old', // 存管账户 - 充值 - 地址认证 @deprecated
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('地址认证'),
      iframeLink: '/web/exchange/custody/assetView/savingAccount/deposit/addressVerify'
    }
  },
  {
    path: '/custody/assetView/savingAccount/record/addressVerify-old', // 存管账户 - 账户记录 -  地址认证 @deprecated
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('地址认证'),
      iframeLink: '/web/exchange/custody/assetView/savingAccount/record/addressVerify'
    }
  },
  {
    path: '/custody/assetView/savingAccount/:type/addressVerify', // 存管账户 - 充值/账户记录 - 地址认证
    beforeEnter: (to: any) => {
      if (to?.params?.type === 'deposit' || to?.params?.type === 'record') {
        return true;
      } else {
        return '/404/';
      }
    },
    component: () => import('@/pages/assets/custody/address-verify/Index.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('地址认证')
      // ssg: {}
    }
  },
  // {
  //   path: '/custody/assetView/savingAccount/withdraw-old', // 存管账户 - 提现 @deprecated
  //   meta: {
  //     layout: 'default',
  //     auth: 'login',
  //     title: t('提现'),
  //     iframeLink: '/web/exchange/custody/assetView/savingAccount/withdraw'
  //   }
  // },
  {
    path: '/custody/assetView/savingAccount/withdraw', // 存管账户 - 重构
    component: () => import('@/pages/assets/custody/withdraw/Index.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('提现'),
      ssg: {}
      // iframeLink: '/web/exchange/custody/assetView/savingAccount/withdraw'
    }
  },
  {
    path: '/custody/assetView/savingAccount/withdraw/riskList', // 存管账户 - 资产暂时冻结
    component: () => import('@/pages/assets/custody/withdraw/WithdrawRiskList.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('资产暂时冻结'),
      ssg: {}
    }
  },
  // {
  //   path: '/custody/assetView/savingAccount/whiteList', // 存管账户 - 白名单管理
  //   meta: {
  //     layout: 'default',
  //     auth: 'login',
  //     title: t('白名单管理'),
  //     iframeLink: '/web/exchange/custody/assetView/savingAccount/whiteList'
  //   }
  // },
  {
    path: '/custody/assetView/savingAccount/rechargeWhiteList', // 存管账户 - 添加充值白名单 - 重构
    component: () => import('@/pages/assets/custody/whiteList/RechargeWhiteList.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('添加充值白名单'),
      ssg: {}
    }
  },
  {
    path: '/custody/assetView/savingAccount/withdrawWhiteList', // 存管账户 - 添加提现白名单 - 重构
    component: () => import('@/pages/assets/custody/whiteList/WithdrawWhiteList.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('添加提现白名单'),
      ssg: {}
    }
  },
  {
    path: '/custody/assetView/savingAccount/rechargeWhiteList-old', // 存管账户 - 添加充值白名单 @deprecated
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('添加充值白名单'),
      iframeLink: '/web/exchange/custody/assetView/savingAccount/rechargeWhiteList'
    }
  },
  {
    path: '/custody/assetView/savingAccount/withdrawWhiteList-old', // 存管账户 - 添加提现白名单 @deprecated
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('添加提现白名单'),
      iframeLink: '/web/exchange/custody/assetView/savingAccount/withdrawWhiteList'
    }
  },
  {
    path: '/custody/assetView/exchangeAccount', // 交易账户
    component: () => import('@/pages/assets/ExchangeAccount.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('交易账户'),
      ssg: {}
    }
  },
  {
    path: '/custody/assetView/fiatAccount',
    component: () => import('@/pages/assets/fiat/Fiat.vue'),
    redirect: '/custody/assetView/fiatAccount/detail',
    meta: {
      layout: 'default',
      auth: 'login',
      ssg: {}
    },
    children: [
      {
        path: 'detail',
        component: () => import('@/pages/assets/fiat/Overview.vue'),
        title: t('账户概览'),
        ssg: {}
      },
      {
        path: 'record',
        component: () => import('@/pages/assets/fiat/AccountRecord.vue'),
        title: t('账户记录'),
        ssg: {}
      },
      // {
      //   path: 'record-old', // 页面无入口，只能手动输入url [改版前 账户记录页面]
      //   component: () => import('@/pages/assets/fiat/Record.vue'),
      //   title: t('账户记录')
      // },
      {
        path: 'bankAccount',
        component: () => import('@/pages/assets/fiat/BankManagement.vue'),
        title: t('银行账户管理'),
        ssg: {}
      }
    ]
  },
  {
    path: '/custody/assetView/fiatAccount/deposit/quickTransfer', // 法币 - SCB充值
    component: () => import('@/pages/assets/fiat/recharge/Index.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('充值'),
      ssg: {}
    }
  },
  {
    path: '/custody/assetView/fiatAccount/deposit', // 法币充值
    component: () => import('@/pages/assets/fiat/deposit/Index.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('充值'),
      ssg: {}
    }
  },
  {
    path: '/custody/assetView/fiatAccount/deposit/regularTransfer', // 法币 - ZA充值
    component: () => import('@/pages/assets/fiat/ZaDeposit.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('充值'),
      ssg: {}
    }
  },
  {
    path: '/custody/assetView/fiatAccount/regularTransfer/success', // 法币 - ZA充值成功
    component: () => import('@/pages/assets/fiat/DepositSuccess.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('充值发起成功'),
      ssg: {}
    }
  },
  {
    path: '/custody/assetView/fiatAccount/deposit/regularTransfer-old', // React - ZA充值
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('充值'),
      iframeLink: '/web/exchange/custody/assetView/fiatAccount/recharge'
    }
  },
  {
    path: '/custody/assetView/fiatAccount/withdraw', // 法币 - 提现
    component: () => import('@/pages/assets/fiat/Withdraw.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('提现'),
      ssg: {}
    }
  },
  {
    path: '/custody/assetView/fiatAccount/exchange', // 法币 - 兑换
    component: () => import('@/pages/assets/fiat/Exchange.vue'),
    meta: {
      layout: 'default',
      // HKEX-11088 未登录用户可看到入口
      // auth: 'login',
      title: t('货币兑换')
    }
  },
  {
    path: '/custody/assetView/fiatAccount/exchange/success', // 法币 - 兑换发起成功
    component: () => import('@/pages/assets/fiat/ExchangeSuccess.vue'),
    meta: {
      layout: 'default',
      auth: 'login'
    }
  },
  {
    path: '/custody/assetView/fiatAccount/withdraw/success', // 法币存管 - 提现发起成功
    component: () => import('@/pages/assets/fiat/WithdrawSuccess.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      ssg: {}
    }
  },
  //   {
  //     path: '/custody/assetView/fiatAccount/withdraw', // 法币存管 - 提现
  //     meta: {
  //       layout: 'default',
  //       auth: 'login',
  //       title: t('提现'),
  //       iframeLink: '/web/exchange/custody/assetView/fiatAccount/withdraw'
  //     }
  //   },
  {
    path: '/custody/assetView/brokerageAccount', // OPT账户
    component: () => import('@/pages/assets/opt/OptAccount.vue'),
    redirect: '/custody/assetView/brokerageAccount/detail',
    meta: {
      layout: 'default',
      auth: 'login'
      // ssg: {}
    },
    children: [
      {
        path: 'detail',
        component: () => import('@/pages/assets/opt/OptAccountDetail.vue'),
        title: t('经纪账户'),
        ssg: {}
      },
      {
        path: 'exchangeRecord', // OPT账户交易记录
        component: () => import('@/pages/assets/opt/ExchangeRecord.vue'),
        title: t('经纪账户明细'),
        ssg: {}
      }
    ]
  },
  // omnibus 预存金账户
  {
    path: '/custody/assetView/preFund', // 资产总览 - 预存金账户
    component: () => import('@/pages/assets/pre-fund/Index.vue'),
    redirect: '/custody/assetView/preFund/detail',
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('预存金账户')
    },
    children: [
      {
        path: 'detail',
        component: () => import('@/pages/assets/pre-fund/detail/Index.vue'),
        title: t('账户概览')
      },
      // HKEX-9100, 去除账户记录
      // {
      //   path: 'record',
      //   component: () => import('@/pages/assets/pre-fund/records/Index.vue'),
      //   title: t('账户记录')
      // },
      {
        path: 'transferRecord',
        component: () => import('@/pages/assets/pre-fund/transferRecords/Index.vue'),
        title: t('preFund.accountTransferHistory')
      }
    ]
  },
  {
    path: '/custody/assetView/disposalAccount', // 处置账户
    component: () => import('@/pages/assets/disposal/disposalAccount.vue'),
    redirect: '/custody/assetView/disposal/detail',
    meta: {
      layout: 'default',
      auth: 'login'
      // ssg: {}
    },
    children: [
      {
        path: 'detail',
        component: () => import('@/pages/assets/disposal/disposalAccountDetail.vue'),
        title: t('处置账户'),
        ssg: {}
      },
      {
        path: 'exchangeRecord', // 处置账户交易记录
        component: () => import('@/pages/assets/disposal/ExchangeRecord.vue'),
        title: t('处置账户明细'),
        ssg: {}
      }
    ]
  },
  // ETF 账户
  {
    path: '/custody/assetView/etfAccount',
    component: () => import('@/pages/assets/etf-account/etfAccount.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('ETF账户')
    },
    children: [
      {
        path: '', // ETF 总览页面
        component: () => import('@/pages/assets/etf-account/Index.vue'),
        title: t('账户概览')
      },
      {
        path: 'fundDetail/:symbols',
        component: () => import('@/pages/assets/etf-account/fund-detail/fundDetail.vue'),
        title: t('基金详情'),
        children: [
          {
            path: '', // 基金详情页
            component: () => import('@/pages/assets/etf-account/fund-detail/Index.vue'),
            title: t('基金详情')
          },
          {
            path: 'records', // 申购赎回页面
            component: () => import('@/pages/assets/etf-account/fund-detail/records/Index.vue'),
            title: t('申购赎回记录')
          }
        ]
      }
    ]
  }
];

export default custody;
