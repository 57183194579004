
import { kycApi } from '@/config/kyc';
import { i18n } from '@/lang/i18n';
import { toast } from '@/utils/toast';
import CONST from '@/config/const';
const { t } = i18n.global;
export const useKycInfoStore = defineStore('kycInfoStore', {
  state: () => ({
    // kyc信息
    kycInfo: <any>{},
    kycVendor: 'sumsub', // jumio|sumsub
    personalIdentificationType: <any>null,
    informationList: reactive<any>([
      {
        label: t('个人信息'),
        key: 'personalStatus',
        // href: '/user/start-jumio',
        href: '/user/base-auth',
        index: 1
      },
      {
        label: t('地址证明'),
        key: 'addressStatus',
        href: '/user/kycaddress',
        index: 2
      },
      {
        label: t('财务信息'),
        key: 'financialStatus',
        href: '/user/tex',
        index: 3
      },
      {
        label: t('资产证明'),
        key: 'assetsProofStatus',
        href: '/user/kycassets',
        index: 4
      },
      {
        label: t('协议签署'),
        key: 'signatureStatus',
        href: '/user/agreement',
        index: 5
      }
    ]),
    authorizedInformationList: reactive<any>([
      {
        label: t('个人信息'),
        key: 'personalStatus',
        href: '/user/baseauthenterprise',
        index: 1
      },
      {
        label: t('证件及人脸识别'),
        key: 'facialStatus',
        href: '/user/kycJumioenterprise',
        index: 2
      },
      {
        label: t('授权文件'),
        key: 'authorizationStatus',
        href: '/user/kycauthorizatio',
        index: 3
      },
      {
        label: t('其他材料'),
        key: 'supplementaryStatus',
        href: '/user/kycother',
        index: 4
      }

    ]),
    certificateList: reactive<any>([
      {
        label: t('添加同名银行卡'),
        key: 'bankCardStatus'
      }, {
        label: t('转账至HashKey Exchange'),
        key: 'bankTransferStatus'
      },
      {
        label: t('上传转账证明'),
        key: 'bankProofStatus'
      }
    ]),
    examinationList: reactive<any>([
      {
        label: t('投资经验问卷'),
        key: 'riskAssessmentStatus',
        href: '/user/kycrishappraisal',
        index: 1
      }, {
        label: t('虚拟货币知识考试'),
        key: 'vaTestStatus',
        href: '/user/knowledgeytest',
        index: 2
      }
    ]),
    hashkeyCustodyList: reactive<any>([
      {
        label: t('HashKey Xpert 补交文件'),
        key: 'custodyApplyStatus',
        href: '/user/hashkeycustody',
        index: 1
      }, {
        label: t('协议签署'),
        key: 'custodyApplyStatus',
        href: '/user/hashkeycustody',
        index: 2
      }
    ])
  }),
  getters: {
    // 获取kyc信息
    getKycInfo(state) {
      const { kycInfo } = state;
      return kycInfo;
    },
    // KYC 已认证
    kycCertified({ kycInfo: { totalAuditStatus } }) {
      return totalAuditStatus === CONST.KycTotalStatusEnum.PASS;
    },
    // KYC 未认证
    kycNotCertified({ kycInfo: { totalAuditStatus } }) {
      // 考虑到网络延迟问题，必须保证 totalAuditStatus 有内容
      return typeof totalAuditStatus !== 'undefined' && totalAuditStatus !== CONST.KycTotalStatusEnum.PASS;
    },
    getInformationList(state) {
      const { kycInfo, informationList } = state;
      const newList: any = [];
      Object.keys(kycInfo).forEach((key: string) => {
        if (key == 'personal') {
          Object.keys(kycInfo[key]).forEach((item: string) => {
            informationList.forEach((listItem: any, listIndex: any) => {
              if (listItem.key == item) {
                if (item == 'assetsProofStatus' && kycInfo.personal.skipAssetProof) {
                  newList.push({
                    label: listItem.label,
                    key: listItem.key,
                    index: listItem.index,
                    href: listItem.href,
                    skipAssetProof: kycInfo.personal.skipAssetProof,
                    ...kycInfo[key][item]
                  });
                } else {
                  newList.push({
                    label: listItem.label,
                    key: listItem.key,
                    index: listItem.index,
                    href: listItem.href,
                    ...kycInfo[key][item]
                  });
                }
              }
            });
          });
        }
      });
      return newList.sort((a: any, b: any) => {
        return a.index - b.index;
      });
    },
    getAuthorizedInformationList(state) {
      const { kycInfo, authorizedInformationList } = state;
      const newList: any = [];
      Object.keys(kycInfo).forEach((key: string) => {
        if (key == 'trader') {
          Object.keys(kycInfo[key]).forEach((item: string) => {
            authorizedInformationList.forEach((listItem: any, listIndex: any) => {
              if (listItem.key == item) {
                newList.push({
                  label: listItem.label,
                  key: listItem.key,
                  index: listItem.index,
                  href: listItem.href,
                  ...kycInfo[key][item]
                });
              }
            });
          });
        }
      });
      return newList.sort((a: any, b: any) => {
        return a.index - b.index;
      });
    },
    getCertificateList(state) {
      const { kycInfo, certificateList } = state;
      const newList: any = [];
      Object.keys(kycInfo).forEach((key: string) => {
        if (key == 'personal') {
          Object.keys(kycInfo[key]).forEach((item: string) => {
            certificateList.forEach((listItem: any, listIndex: any) => {
              if (listItem.key == item) {
                newList.push({
                  label: listItem.label,
                  key: listItem.key,
                  ...kycInfo[key][item]
                });
              }
            });
          });
        }
      });
      return newList.sort((a: any, b: any) => {
        return a.index - b.index;
      });
    },
    getExaminationList(state) {
      const { kycInfo, examinationList } = state;
      const newList: any = [];
      Object.keys(kycInfo).forEach((key: string) => {
        if (key == 'personal') {
          Object.keys(kycInfo[key]).forEach((item: string) => {
            examinationList.forEach((listItem: any, listIndex: any) => {
              if (listItem.key == item) {
                newList.push({
                  label: listItem.label,
                  key: listItem.key,
                  index: listItem.index,
                  ...kycInfo[key][item]
                });
              }
            });
          });
        }
      });
      return newList.sort((a: any, b: any) => {
        return a.index - b.index;
      });
    },
    getPersonalIdentificationType(state) {
      const { personalIdentificationType } = state;
      return personalIdentificationType;
    },
    getHashkeyCustodyList(state) {
      const { kycInfo, hashkeyCustodyList } = state;
      const newList: any = [];
      Object.keys(kycInfo).forEach((key: string) => {
        if (key == 'personal' || key == 'corporate') {
          Object.keys(kycInfo[key]).forEach((item: string) => {
            hashkeyCustodyList.forEach((listItem: any, listIndex: any) => {
              if (listItem.key == item) {
                newList.push({
                  label: listItem.label,
                  key: listItem.key,
                  ...kycInfo[key][item]
                });
              }
            });
          });
        }
      });
      return newList.sort((a: any, b: any) => {
        return a.index - b.index;
      });
    },
    getCustodyInfo(state) {
      return state.kycInfo.personal?.custodyApplyStatus || state.kycInfo.corporate?.custodyApplyStatus;
    }
  },
  actions: {
    async getRedirectUrlByStatus() {
      let url = '';
      if (this.getCustodyInfo.auditStatus == CONST.KycMaterialAuditStatusEnum.MATERIAL_STATUS_UNKNOWN) {
        await kycApi.openCustodyApply()
          .then((data: any) => {
            if (data.success) {
              url = '/user/hashkeycustody';
            } else {
              toast.error(data.message);
            }
          });
      } else if (this.getCustodyInfo.auditStatus == CONST.KycMaterialAuditStatusEnum.MATERIAL_STATUS_EMPTY || this.getCustodyInfo.auditStatus == CONST.KycMaterialAuditStatusEnum.MATERIAL_STATUS_REJECT) {
        url = '/user/hashkeycustody';
      } else if (this.getCustodyInfo.auditStatus == CONST.KycMaterialAuditStatusEnum.MATERIAL_STATUS_REVIEW) {
        url = '/user/kyc?source=user';
      }
      return url;
    },
    getKycVendor() {
      kycApi.getActiveKycVendor()
        .then((data: any) => {
          if (data.success) {
            this.kycVendor = data.data?.vendor;
          } else {
            toast.error(data.message);
          }
        });
    },
    requestKycInfo() {
      kycApi.getUserKycInfo()
        .then((data: any) => {
          if (data.success) {
            this.kycInfo = data.data;
            this.personalIdentificationType = data.data.personal?.personalIdentificationType == 0 ? 3 : data.data.personal?.personalIdentificationType;
            // this.personalIdentificationType = 1;
            // this.kycInfo = {
            //   userType: 7,
            //   openChannel: 3,
            //   supportRetail: true,
            //   totalAuditStatus: 4,
            //   personal: {
            //     personalStatus: {
            //       auditStatus: 5,
            //       auditTime: '',
            //       expirationTime: '',
            //       score: 0,
            //       remainderNum: 0,
            //       riskCode: '',
            //       riskKind: '',
            //       riskKycPass: false,
            //       freeTime: ''
            //     },
            //     addressStatus: {
            //       auditStatus: 5,
            //       auditTime: '',
            //       expirationTime: '',
            //       score: 0,
            //       remainderNum: 0,
            //       riskCode: '',
            //       riskKind: '',
            //       riskKycPass: false,
            //       freeTime: ''
            //     },
            //     financialStatus: {
            //       auditStatus: 5,
            //       auditTime: '',
            //       expirationTime: '',
            //       score: 0,
            //       remainderNum: 0,
            //       riskCode: '',
            //       riskKind: '',
            //       riskKycPass: false,
            //       freeTime: ''
            //     },
            //     assetsProofStatus: {
            //       auditStatus: 5,
            //       auditTime: '',
            //       expirationTime: '',
            //       score: 0,
            //       remainderNum: 0,
            //       riskCode: '',
            //       riskKind: '',
            //       riskKycPass: false,
            //       freeTime: ''
            //     },
            //     signatureStatus: {
            //       auditStatus: 5,
            //       auditTime: '',
            //       expirationTime: '',
            //       score: 0,
            //       remainderNum: 0,
            //       riskCode: '',
            //       riskKind: '',
            //       riskKycPass: false,
            //       freeTime: ''
            //     },
            //     supplementaryStatus: {
            //       auditStatus: 1,
            //       expirationTime: ''
            //     },
            //     baseInfoApplyStatus: {
            //       auditStatus: 5,
            //       auditTime: '',
            //       expirationTime: '',
            //       score: 0,
            //       remainderNum: 0,
            //       riskCode: '',
            //       riskKind: '',
            //       riskKycPass: false,
            //       freeTime: ''
            //     },
            //     vaAndRiskStatus: {
            //       auditStatus: 5,
            //       auditTime: '',
            //       expirationTime: '',
            //       score: 0,
            //       remainderNum: 0,
            //       riskCode: '',
            //       riskKind: '',
            //       riskKycPass: false,
            //       freeTime: ''
            //     },
            //     bankApplyStatus: {
            //       auditStatus: 5,
            //       auditTime: '',
            //       expirationTime: '',
            //       score: 0,
            //       remainderNum: 0,
            //       riskCode: '',
            //       riskKind: '',
            //       riskKycPass: false,
            //       freeTime: ''
            //     },
            //     vaTestStatus: {
            //       auditStatus: 5,
            //       auditTime: '',
            //       expirationTime: '',
            //       score: 0,
            //       remainderNum: 0,
            //       riskCode: '',
            //       riskKind: '',
            //       riskKycPass: false,
            //       freeTime: ''
            //     },
            //     riskAssessmentStatus: {
            //       auditStatus: 5,
            //       auditTime: '',
            //       expirationTime: '',
            //       score: 0,
            //       remainderNum: 3,
            //       riskCode: '',
            //       riskKind: '',
            //       riskKycPass: false,
            //       freeTime: ''
            //     },
            //     bankCardStatus: {
            //       auditStatus: 1,
            //       auditTime: '',
            //       expirationTime: '',
            //       score: 0,
            //       remainderNum: 0,
            //       riskCode: '',
            //       riskKind: '',
            //       riskKycPass: false,
            //       freeTime: ''
            //     },
            //     bankTransferStatus: {
            //       auditStatus: 1,
            //       auditTime: '',
            //       expirationTime: '',
            //       score: 0,
            //       remainderNum: 0,
            //       riskCode: '',
            //       riskKind: '',
            //       riskKycPass: false,
            //       freeTime: ''
            //     },
            //     bankProofStatus: {
            //       auditStatus: 1,
            //       auditTime: '',
            //       expirationTime: '',
            //       score: 0,
            //       remainderNum: 0,
            //       riskCode: '',
            //       riskKind: '',
            //       riskKycPass: false,
            //       freeTime: ''
            //     },
            //     skipAssetProof: false,
            //     upgradeStatus: {
            //       auditStatus: 6,
            //       auditTime: '',
            //       expirationTime: '',
            //       score: 0,
            //       remainderNum: 0,
            //       riskCode: '',
            //       riskKind: '',
            //       riskKycPass: false,
            //       freeTime: ''
            //     },
            //     offlineWitnessStatus: {
            //       auditStatus: 6,
            //       auditTime: '',
            //       expirationTime: '',
            //       score: 0,
            //       remainderNum: 0,
            //       riskCode: '',
            //       riskKind: '',
            //       riskKycPass: false,
            //       freeTime: ''
            //     },
            //     offline: {
            //       auditStatus: 1,
            //       auditTime: '',
            //       expirationTime: '',
            //       score: 0,
            //       remainderNum: 0,
            //       riskCode: '',
            //       riskKind: '',
            //       riskKycPass: false,
            //       freeTime: ''
            //     },

            //     piStatus: 1,
            //     retailStatus: 1,
            //     personalIdentificationType: 1
            //   },
            //   confirmBank: true
            // };
          }
        });
    },
    setPersonalIdentificationType(type: any) {
      this.personalIdentificationType = type;
    }
  }
});
