<template>
  <el-menu>
    <slot />
  </el-menu>
</template>

<script lang="ts">
// namespaced components
export default {
  name: 'HkMenu'
};
</script>

<script lang="ts" setup>
// const props = defineProps();
</script>

<style lang="scss" scoped>
</style>