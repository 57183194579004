/**
 * @file 基于 localforage 的 indexDB 封装，和基于 localStorage 的缓存封装
 * @description 用于存储数据到 localStorage 或 indexDB
 */
import localforage from 'localforage';

/** api 数据缓存indexDB使用实例 */
export const storageApi = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: 'api',
  storeName: 'kvs',
  description: 'api storage'
});

/**
 * 获取缓存
 * @param key 缓存key
 * @returns 缓存的值，如果解析失败则返回 null
 */
export function getStorage(key: string): Record<string, any> | string | number | boolean | null {
  const storeItem = localStorage.getItem(key);
  let store = null;

  try {
    // 尝试解析缓存的 JSON 字符串
    store = storeItem ? JSON.parse(storeItem) : null;
  } catch {
    // 如果解析失败，移除该缓存项
    localStorage.removeItem(key);
    store = null;
  }

  return store;
}

/**
 * 设置缓存
 * @param key 缓存key
 * @param value 缓存的值，可以是对象、字符串或数字
 */
export function setStorage(key: string, value: Record<string, any> | string | number | boolean | null) {
  // 将值转换为 JSON 字符串并存储
  localStorage.setItem(key, JSON.stringify(value));
}

/**
 * 删除缓存
 * @param key 缓存key
 */
export function removeStorage(key: string) {
  // 移除指定的缓存项
  localStorage.removeItem(key);
}
