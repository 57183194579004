import { Directive } from 'vue';

export const defaultImg: Directive = {
  mounted(el) {
    el.onerror = () => {
      // TODO src 必须是链接才能实现
    };
  }
};

