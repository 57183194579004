import { App } from 'vue';
import HkAutocomplete from './HkAutocomplete.vue';
import HkAvatar from './HkAvatar.vue';
import HkBreadcrumb from './HkBreadcrumb.vue';
import HkButton from './HkButton.vue';
import HkCalendar from './HkCalendar.vue';
import HkCard from './HkCard.vue';
import HkCarousel from './HkCarousel.vue';
import HkCheckbox from './HkCheckbox.vue';
import HkCheckboxGroup from './HkCheckboxGroup.vue';
import HkCollapse from './HkCollapse.vue';
import HkColorPicker from './HkColorPicker.vue';
import HkDatePicker from './HkDatePicker.vue';
import HkDialog from './HkDialog.vue';
import HkDrawer from './HkDrawer.vue';
import HkDropdown from './HkDropdown.vue';
import HkEmpty from './HkEmpty.vue';
import HkForm from './HkForm.vue';
import HkInput from './HkInput.vue';
import HkInputNumber from './HkInputNumber.vue';
import HkMenu from './HkMenu.vue';
import HkPagination from './HkPagination.vue';
import HkPopconfirm from './HkPopconfirm.vue';
import HkPopover from './HkPopover.vue';
import HkProgress from './HkProgress.vue';
import HkRadio from './HkRadio.vue';
import HkRate from './HkRate.vue';
import HkSelect from './HkSelect.vue';
import HkSlider from './HkSlider.vue';
import HkSteps from './HkSteps.vue';
import HkSwitch from './HkSwitch.vue';
import HkTable from './HkTable.vue';
import HkTabs from './HkTabs.vue';
import HkTag from './HkTag.vue';
import HkTooltip from './HkTooltip.vue';
import HkTransfer from './HkTransfer.vue';
import HkUpload from './HkUpload.vue';
 
const components = [
  HkAutocomplete,
  HkAvatar, 
  HkBreadcrumb, 
  HkButton, 
  HkCalendar, 
  HkCard,
  HkCarousel,
  HkCheckbox,
  HkCheckboxGroup,
  HkCollapse,
  HkColorPicker,
  HkDatePicker,
  HkDialog,
  HkDrawer,
  HkDropdown,
  HkEmpty,
  HkForm,
  HkInput,
  HkInputNumber,
  HkMenu,
  HkPagination,
  HkPopconfirm,
  HkPopover,
  HkProgress,
  HkRadio,
  HkRate,
  HkSelect,
  HkSlider,
  HkSteps,
  HkSwitch,
  HkTable,
  HkTabs,
  HkTag,
  HkTooltip,
  HkTransfer,
  HkUpload
];
export default function(app: App): void {
  for (const component of components) {
    app.component(component.name, component);
  }
}