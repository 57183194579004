
export const useAppStore = defineStore('appStore', {
  state: () => ({
    jsbridgeStatus: <boolean>false // jsbridge状态，true表示jsbridge ready
  }),
  getters: {},
  actions: {
    changeJsbridgeStatus(val: boolean) {
      this.jsbridgeStatus = val;
    }
  }
});
