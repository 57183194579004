/**
 * @file websocket （行情、资产、订单）
 */
import WS from '@/utils/ws_1.0';
import { wsUrl } from '@/config/ws';
import { lt } from '@/utils/number';
import { useConfigStore } from '@/store/config';
import { pinia } from './index';

const configStore = useConfigStore(pinia);

export const useWsStore = defineStore('wsStore', {
  state: () => ({
    // 行情ws
    quoteWS: <any | null>null,
    // 用户ws
    userWS: <any | null>null,
    quoteWsOpen: false,
    userWsOpen: false,
    // 行情
    quotationData: <Record<string, any>>{}
  }),
  getters: {
    /**
     * 所有交易对行情（结构如下）
     *
     * { BTCUSDT: {...行情数据}, ETHUSDT: {...行情数据}, ... }
     */
    quotation(state) {
      const quotation: any = {};

      Object.keys(state.quotationData).forEach(symbol => {
        const symbolQuota: any = { ...state.quotationData[symbol] };
        const rate = symbolQuota.m;
        // 修改成不是跌就是涨
        if (lt(rate, 0)) {
          symbolQuota.rateClass = 'down';
        } else {
          symbolQuota.rateClass = 'up';
        }

        quotation[symbol] = {
          ...symbolQuota
        };
      });

      return quotation;
    }
  },
  actions: {
    /**
     * 创建websocket连接
     *
     * @param type 连接类型 quote-行情  user-用户相关
     */
    createWS(type = 'quote') {
      if (type === 'quote' && !this.quoteWS) {
        this.quoteWS = new WS(wsUrl.quoteWs, { onOpen: () => {
          this.quoteWsOpen = true;
        } });
      }

      if (type === 'user' && !this.userWS) {
        this.userWS = new WS(wsUrl.userWs, { onOpen: () => {
          this.userWsOpen = true;
        } });
      }
    },
    /**
     * 取消订阅
     *
     * @param subId 订阅ID
     * @param ws ws连接对象
     */
    unSub(subId: string, ws?: any) {
      (ws || this.quoteWS)?.cancel(subId);
    },
    // 订阅所有币种行情
    subQuotation() {
      this.quoteWS?.sub(
        {
          event: 'sub',
          id: 'ws-broker',
          topic: 'slowBroker',
          params: {
            org: configStore.orgId,
            realtimeInterval: '24h',
            binary: import.meta.env.MODE === 'production'
          }
        },
        () => {
          // TODO
        },
        (res: any) => {
          res.data.forEach((item: any) => {
            this.quotationData[item.s] = item;
          });
        }
      );
    }
  }
});
