import { ref, onMounted } from 'vue';

export function usePopstate() {
  const callbacks = ref<any>([]);

  const stateChange = (fn: (e: any) => void) => {
    callbacks.value.push(fn);
  };

  const onPopstate = (e: any) => {
    callbacks.value.forEach((fn: any) => {
      fn(e);
    });
  };

  onMounted(() => window.addEventListener('popstate', onPopstate, false));
  // onUnmounted(() => window.removeEventListener('popstate', onPopstate, false));

  return { stateChange };
}
