<template>
  <el-drawer>
    <slot />
  </el-drawer>
</template>
  
<script lang="ts">
// namespaced components
export default {
  name: 'HkDrawer'
};
</script>
  
<script lang="ts" setup>
// const props = defineProps();    
</script>
  
  <style lang="scss" scoped>
  </style>