<template>
  <el-empty>
    <slot />
  </el-empty>
</template>

<script lang="ts">
// namespaced components
export default {
  name: 'HkEmpty'
};
</script>

<script lang="ts" setup>
// const props = defineProps();
</script>

<style lang="scss" scoped>
</style>