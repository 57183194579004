<template>
  <el-button>
    <slot />
  </el-button>
</template>

<script lang="ts">
// namespaced components
export default {
  name: 'HkButton'
};
</script>

<script lang="ts" setup>
// const props = defineProps();
</script>

<style lang="scss" scoped>
$sizeArray: (sm 8px 14px 36px) (md 10px 16px 40px) (lg 10px 18px 44px) (xl 12px 20px 48px) (2xl 16px 28px 60px) !default;
$typeArray: false true !default;

@mixin layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@each $type in $typeArray {
  @each $size in $sizeArray {
    @if $type == false {
      // false
      // Primary
      .#{$type}-primary-#{nth($size, 1)} {
        padding: nth($size, 2) nth($size, 3);
        height: nth($size, 4);
        @include layout();

        border-radius: 8px;
        background-color: var(--gray-800);
        box-shadow: none;
        border: none;
        &:hover {
          background-color: var(--gray-700);
          border-color: var(--gray-700);
          box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        }
        &:active {
          background-color: var(--gray-800);
          border-color: var(--gray-800);
          box-shadow: 0 0 0 4px var(--gray-100);
        }
        &:disabled {
          background-color: var(--gray-300);
          border-color: var(--gray-300);
        }
      }
      // Secondary gray
      .#{$type}-sg-#{nth($size, 1)} {
        padding: nth($size, 2) nth($size, 3);
        height: nth($size, 4);
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        @include layout();

        background-color: var(--base-white);
        border: 1px solid var(--gray-300);
        color: var(--gray-700);
        &:hover {
          background-color: var(--gray-50);
          border: 1px solid var(--gray-300);
        }
        &:active,&:focus {
          background-color: var(--gray-50);
          box-shadow: 0 0 0 4px var(--gray-100);
        }
        &:disabled {
          color: var(--gray-300) !important;
          border: 1px solid var(--gray-200);
          background-color: var(--base-white) !important;
        }
      }
      // Secondary color
      .#{$type}-sc-#{nth($size, 1)} {
        padding: nth($size, 2) nth($size, 3);
        height: nth($size, 4);
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        @include layout();

        background-color: rgba(142, 20, 115, 0.1);
        border: none;
        color: var(--primary-500);
        &:hover {
          background-color: rgba(142, 20, 115, 0.15);
        }
        // &:active,&:focus {
        //   background-color: rgba(142, 20, 115, 0.1);
        //   box-shadow: 0px 0px 0px 4px rgba(142, 20, 115, 0.15);
        // }
        &:disabled {
          background-color: var(--base-white);
          border: 1px solid rgba(142, 20, 115, 0.1);
        }
      }
    } @else {
      // true
      // Primary
      .#{$type}-primary-#{nth($size, 1)} {
        padding: nth($size, 2) nth($size, 3);
        height: nth($size, 4);
        border-radius: 8px;
        @include layout();

        background-color: var(--error-600);
        border: 1px solid var(--error-600);
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        &:hover {
          background-color: var(--error-700);
          border: 1px solid var(--error-700);
        }
        &:active {
          background-color: var(--error-800);
          border: 1px solid var(--error-800);
          box-shadow: 0 0 0 4px var(--error-100);
        }
        &:disabled {
          background-color: var(--error-200);
          border: 1px solid var(--error-200);
        }
      }
      // Secondary gray
      .#{$type}-sg-#{nth($size, 1)} {
        padding: nth($size, 2) nth($size, 3);
        height: nth($size, 4);
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        @include layout();

        background-color: var(--base-white);
        border: 1px solid var(--error-300);
        &:hover {
          background-color: var(--error-50);
          border: 1px solid var(--error-300);
          box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        }
        &:active {
          box-shadow: 0 0 0 4px var(--error-100);
        }
        &:disabled {
          border: 1px solid var(--error-200);
        }
      }
      // Secondary color
      .#{$type}-sc-#{nth($size, 1)} {
        padding: nth($size, 2) nth($size, 3);
        height: nth($size, 4);
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        @include layout();

        background-color: var(--error-50);
        border: 1px solid var(--error-50);
        &:hover {
          background-color: var(--error-100);
          border: 1px solid var(--error-100);
        }
        &:active {
          box-shadow: 0 0 0 4px var(--error-100);
        }
        &:disabled {
          background-color: var(--error-25);
          border: 1px solid var(--error-25);
        }
      }
    }
  }
}
</style>
