<template>
  <el-tooltip
    v-bind="$attrs"
    class="hk-tooltip"
    popper-class="hk-tooltip-popper"
  >
    <template #default>
      <slot name="default" />
    </template>
    <template #content>
      <slot name="content" />
    </template>
  </el-tooltip>
</template>

<script lang="ts">
export default {
  name: 'HkTooltip'
};
</script>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
</style>